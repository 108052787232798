<template>
  <v-container class="mb-5">
    <v-row class="mb-8">
      <span class="text-md-h6 text-lg-h5">{{ patient.name | formatCdaName }}</span>
    </v-row>

    <!--<v-row><v-divider></v-divider></v-row>-->

    <v-row>
      <!-- Datensatzinformationen -->
      <v-col cols="3">
        <v-row class="attribute-section-header">Datensatzinformationen</v-row>
      </v-col>
      <v-col>
        <v-row>
          <span class="font-weight-medium mr-1">Id-Root:</span>
          {{ patient.id.root }}
        </v-row>
        <v-row>
          <span class="font-weight-medium mr-1">Id-Extension:</span>
          {{ patient.id.extension }}
        </v-row>
        <v-row>
          <span class="font-weight-medium mr-1">Id-AssigningAuthorityName:</span>
          {{ patient.id.assigningAuthorityName }}
        </v-row>
      </v-col>
    </v-row>

    <v-row><v-divider></v-divider></v-row>

    <v-row>
      <!-- Personendaten -->
      <v-col cols="3">
        <v-row class="attribute-section-header">Personendaten</v-row>
      </v-col>
      <v-col>
        <v-row>
          <span class="font-weight-medium mr-1">Sozialversicherungsnummer:</span>
          {{ patient.sozialversicherungsnummer || 'Keine Angabe' }}
        </v-row>
        <v-row>
          <span class="font-weight-medium mr-1">Adresse:</span>
          {{ patient.address | formatAddress }}
        </v-row>
        <v-row>
          <span class="font-weight-medium mr-1">Geschlecht:</span>
          {{ patient.gender | formatGender }}
        </v-row>
        <v-row>
          <span class="font-weight-medium mr-1">Geburtsdatum:</span>
          {{ patient.birthTime | formatDate() }}
        </v-row>
      </v-col>
    </v-row>

    <v-row><v-divider></v-divider></v-row>

    <v-row>
      <!-- Kontaktdaten -->
      <v-col cols="3">
        <v-row class="attribute-section-header">Kontaktdaten</v-row>
      </v-col>
      <v-col>
        <v-row v-for="telecom in patient.telecoms" :key="telecom">
          {{ telecom.split(':')[1] }}
        </v-row>
        <v-row v-if="patient.telecoms.length === 0">
          <td
            colspan="4"
            class="caption text-center"
            style="column-span: all"
          >
            Keine Daten vorhanden
          </td>
        </v-row>
      </v-col>
    </v-row>

    <v-row><v-divider></v-divider></v-row>

    <v-row>
      <!-- Laborwerte -->
      <v-col cols="3">
        <v-row class="attribute-section-header">Laborwerte</v-row>
      </v-col>
      <v-col>
        <v-row>
          <td
            :hidden="patient.labValues.length !== 0"
            colspan="4"
            class="caption text-center"
            style="column-span: all"
          >
            Keine Daten vorhanden
          </td>
          <v-simple-table
            :hidden="patient.labValues.length === 0"
            dense
            style="margin-left: -15px"
          >
            <thead>
              <tr>
                <th class="text-left">Analyse</th>
                <th class="text-left">Ergebnis</th>
                <th class="text-left">Referenzbereich</th>
                <th class="text-left">Einheit</th>
                <th class="text-center">Interpretation</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="labValue in patient.labValues"
                :key="labValue.analysis"
                style="background: #fff !important"
              >
                <td>{{ labValue.analysis }}</td>
                <td>{{ labValue.value }}</td>
                <td>{{ labValue.referenceArea.low }} - {{ labValue.referenceArea.high }}</td>
                <td>{{ labValue.unit }}</td>
                <td class="text-center">{{ labValue.interpretation }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-row>
      </v-col>
    </v-row>

    <v-row><v-divider></v-divider></v-row>

    <v-row>
      <!-- Diagnosen -->
      <v-col cols="3">
        <v-row class="attribute-section-header">Diagnosen</v-row>
      </v-col>
      <v-col>
        <v-row>
          <td
            :hidden="patient.diagnoses.length !== 0"
            colspan="4"
            class="caption text-center"
            style="column-span: all"
          >
            Keine Daten vorhanden
          </td>
          <v-simple-table
            :hidden="patient.diagnoses.length === 0"
            dense
            style="margin-left: -15px"
          >
            <thead>
            <tr>
              <th class="text-left">Zeitraum oder Zeitpunkt</th>
              <th class="text-left">Diagnosetext</th>
              <th class="text-left">Code [Codesystem]</th>
              <th class="text-left">Diagnoseart</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="diagnosis in patient.diagnoses"
              :key="diagnosis.id"
              style="background: #fff !important"
            >
              <td>{{ diagnosis.time | formatDate }}</td>
              <td>{{ diagnosis.text }}</td>
              <td>{{ diagnosis.code.code }} [{{ diagnosis.code.codeSystem }}]</td>
              <td>{{ diagnosis.type }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-row>
      </v-col>
    </v-row>

    <v-row><v-divider></v-divider></v-row>

    <v-row>
      <!-- Rezepte -->
      <v-col cols="3">
        <v-row class="attribute-section-header">Rezepte</v-row>
      </v-col>
      <v-col>
        <v-row>
          <td
            :hidden="patient.rezepte.length !== 0"
            colspan="4"
            class="caption text-center"
            style="column-span: all"
          >
            Keine Daten vorhanden
          </td>
          <v-simple-table
            :hidden="patient.rezepte.length === 0"
            dense
            style="margin-left: -15px; width: 100%"
            loading
          >
            <thead>
              <tr>
                <th class="text-left">Arznei Pharmazentralnummer</th>
                <th class="text-left">Arznei Bezeichnung</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="rezept in patient.rezepte"
                :key="rezept.id"
                style="background: #fff !important"
              >
                <td>{{ rezept.pharmazentralnummer }}</td>
                <td>{{ rezept.drugName }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-row>
      </v-col>
    </v-row>

    <v-row><v-divider></v-divider></v-row>

    <v-row>
      <!-- EBMEDS Auswertung -->
      <v-col cols="3">
        <v-row class="attribute-section-header">EBMEDS Auswertung</v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-simple-table
            dense
            style="margin-left: -15px; width: 100%"
            loading
          >
            <thead>
            <tr>
              <th class="text-left">lorem</th>
              <th class="text-left">ipsum</th>
              <th class="text-left">foo</th>
              <th class="text-left">bar</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="ebmEvaluation in patient.ebmEvaluations"
              :key="ebmEvaluation.foo"
              style="background: #fff !important"
            >
              <td>{{ ebmEvaluation.lorem }}</td>
              <td>{{ ebmEvaluation.ipsum }}</td>
              <td>{{ ebmEvaluation.foo }}</td>
              <td>{{ ebmEvaluation.bar }}</td>
            </tr>
            <tr
              v-if="patient.ebmEvaluations.length === 0"
              style="background-color: #fff !important"
            >
              <td
                colspan="4"
                class="caption text-center"
                style="column-span: all"
              >
                Noch keine Daten ausgewertet
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-row>
        <v-row class="mt-7">
          <v-btn
            id="loadEbmedsBtn"
            depressed
            color="primary"
            :loading="loadingEbmEvaluations"
            @click="loadEbmeds"
          >
            Daten Auswerten
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Patient',

  props: [
    'patient',
  ],

  data: () => ({
    loadingEbmEvaluations: false,
  }),

  methods: {
    loadEbmeds() {
      this.loadingEbmEvaluations = true;
      setTimeout(() => {
        this.patient.ebmEvaluations = [
          {
            lorem: 'Once',
            ipsum: 'Greenbrier International, Inc.',
            foo: '33992-5000',
            bar: 'Malus mandshurica (Maxim.) Kom. var. sachalinensis (Juz.) Ponomar.',
          }, {
            lorem: 'Often',
            ipsum: 'WOCKHARDT LIMITED',
            foo: '55648-661',
            bar: 'Nototrichium divaricatum D.H. Lorence',
          }, {
            lorem: 'Often',
            ipsum: 'Conopco Inc. d/b/a Unilever',
            foo: '64942-1188',
            bar: 'Hydrocotyle verticillata Thunb. var. fetherstoniana (Jennings) Mathias',
          }, {
            lorem: 'Monthly',
            ipsum: 'Preferred Pharmaceuticals, Inc.',
            foo: '68788-0625',
            bar: 'Chenopodium neomexicanum Standl. var. neomexicanum',
          }, {
            lorem: 'Weekly',
            ipsum: 'STAT Rx USA LLC',
            foo: '42549-557',
            bar: 'Opegrapha rupestris Pers.',
          }];
        this.loadingEbmEvaluations = false;
      }, 1000);

      axios({
        method: 'post',
        url: 'https://httpbin.org/post',
        responseType: 'json',
        data: this.patient,
      })
        .then((resp) => console.dir(resp));
    },
  },
};
</script>

<style scoped>
  th,
  td {
    border-bottom: 0 !important;
  }

  .v-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .attribute-section-header {
    font-size: 17px;
    margin-top: -15px;
    font-weight: 500;
  }
</style>
