import Vue from 'vue';
import dayjs from 'dayjs';
import {
  AddressG1,
  AddressG2,
  AddressG3,
  AdministrativeGender,
  NameG1,
  NameG2,
} from '@/types/cdacommon';
import { defaultDateFormat } from '@/config';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.filter('formatCdaName', (name: NameG1 | NameG2) => {
  if (typeof name === 'string') return name;

  const prefix = name.prefixes.join(' ');
  const given = name.given.join(' ');
  const family = name.family[0];
  const suffix = name.suffixes.join(' ');
  return [prefix, given, family, suffix].filter(Boolean).join(' ');
});

Vue.filter('formatDate', (date: Date, format?: string) => dayjs(date).format(format ?? defaultDateFormat));

Vue.filter('formatGender', (gender: AdministrativeGender) => {
  switch (gender) {
    case 'F':
      return 'Weiblich';

    case 'M':
      return 'Männlich';

    case 'UN':
      return 'Divers';

    default:
      return 'Keine Angabe';
  }
});

Vue.filter('formatAddress', (a: AddressG1 | AddressG2 | AddressG3) => {
  // If AddressG1
  if (typeof a === 'string') return a;

  // If AddressG2
  if ('streetAddressLine' in a) {
    return [a.streetAddressLine, a.postalCode, a.city, a.country]
      .filter((addressElement) => addressElement == null || addressElement !== '')
      .join(', ');
  }

  // If AddressG3
  if ('streetName' in a) {
    return [a.streetName, a.houseNumber, a.postalCode, a.city, a.country]
      .filter((addressElement) => addressElement == null || addressElement !== '')
      .join(', ');
  }

  return 'Keine Angabe';
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
