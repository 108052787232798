/* eslint-disable */
export const pat00014 = `
<?xml-stylesheet type="text/xsl" href="ELGA_Stylesheet.xsl"?>
<ClinicalDocument xmlns:hl7at="urn:hl7-at:v3" xmlns:pharm="urn:ihe:pharm:medication" xmlns:sdtc="urn:hl7-org:sdtc" xmlns="urn:hl7-org:v3" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <realmCode code="AT"/>
  <typeId extension="POCD_HD000040" root="2.16.840.1.113883.1.3"/>
  <templateId root="1.2.40.0.34.6.0.11.0.1"/>
  <templateId root="1.2.40.0.34.7.25.1"/>
  <templateId root="1.2.40.0.34.6.0.11.0.6"/>
  <id assigningAuthorityName="Dr. Test Osteron" extension="1" root="1.2.40.0.34.99.9999"/>
  <code code="EXNDS_Patientendaten" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Datenbankexport EXNDS - Patientendaten">
    <translation code="DatenbankexportEXNDS" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Datenbankexport EXNDS"/>
  </code>
  <title>Datenbankexport</title>
  <hl7at:terminologyDate value="20220101"/>
  <hl7at:formatCode code="urn:hl7-at:exnds-patient:2021"/>
  <hl7at:practiceSettingCode code="F001" codeSystem="1.2.40.0.34.5.12"/>
  <effectiveTime value="20220228214615+0100"/>
  <confidentialityCode code="N" codeSystem="2.16.840.1.113883.5.25" codeSystemName="HL7:Confidentiality" displayName="normal"/>
  <languageCode code="de-AT"/>
  <setId assigningAuthorityName="Dr. Test Osteron" extension="1" root="1.2.40.0.34.99.9999"/>
  <versionNumber value="1"/>
  <recordTarget>
    <patientRole>
      <id assigningAuthorityName="Dr. Test Osteron" extension="PAT00014" root="1.2.40.0.34.99.9999.1.2"/>
      <id assigningAuthorityName="Österreichische Sozialversicherung" nullFlavor="UNK" root="1.2.40.0.10.1.4.3.1"/>
      <addr use="H">
        <streetAddressLine></streetAddressLine>
        <postalCode></postalCode>
        <city></city>
        <country>AUT</country>
      </addr>
      <patient>
        <name>
          <given>Romana</given>
          <family>TEST</family>
        </name>
        <administrativeGenderCode code="F" codeSystem="2.16.840.1.113883.5.1" codeSystemName="HL7:AdministrativeGender" displayName="Weibliche Person"/>
        <birthTime value="19350111"/>
      </patient>
    </patientRole>
  </recordTarget>
  <author>
    <functionCode code="OL" codeSystem="1.2.40.0.34.99.9999.10.1" codeSystemName="doctory.elga.cda.Funktionscodes" displayName="Ärztliche Ordinationsleitung"/>
    <time value="20220228214615+0100"/>
    <assignedAuthor>
      <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
      <code code="107" codeSystem="1.2.40.0.34.5.160" codeSystemName="gda-rollen" displayName="Fachärztin/Facharzt für Chirurgie"/>
      <telecom use="WP" value="mailto:max@muster.com"/>
      <assignedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </assignedPerson>
      <representedOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedOrganization>
    </assignedAuthor>
  </author>
  <custodian>
    <assignedCustodian>
      <representedCustodianOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedCustodianOrganization>
    </assignedCustodian>
  </custodian>
  <legalAuthenticator>
    <time value="20220228214615+0100"/>
    <signatureCode code="S"/>
    <assignedEntity>
      <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
      <telecom use="WP" value="mailto:max@muster.com"/>
      <assignedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </assignedPerson>
      <representedOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedOrganization>
    </assignedEntity>
  </legalAuthenticator>
  <participant typeCode="CALLBCK">
    <templateId root="1.2.40.0.34.6.0.11.1.20"/>
    <associatedEntity classCode="PROV">
      <telecom use="WP" value="mailto:max@muster.com"/>
      <associatedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </associatedPerson>
      <scopingOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </scopingOrganization>
    </associatedEntity>
  </participant>
  <componentOf>
    <encompassingEncounter>
      <code code="AMB" codeSystem="2.16.840.1.113883.5.4" codeSystemName="HL7:ActCode" displayName="ambulant"/>
      <effectiveTime>
        <low value=""/>
        <high value=""/>
      </effectiveTime>
      <responsibleParty>
        <assignedEntity>
          <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
          <telecom use="WP" value="mailto:max@muster.com"/>
          <assignedPerson>
            <name>
              <prefix>Dr.</prefix>
              <given>Test</given>
              <family>Osteron</family>
            </name>
          </assignedPerson>
        </assignedEntity>
      </responsibleParty>
      <location>
        <healthCareFacility>
          <code code="158" codeSystem="1.2.40.0.34.5.2" displayName="Fachärztin/Facharzt"/>
          <serviceProviderOrganization>
            <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
            <name>Dr. Test Osteron</name>
            <telecom value="mailto:max@muster.com"/>
            <telecom value="www.osteron-praxis.at"/>
            <addr>
              <streetAddressLine>Im Walde 55</streetAddressLine>
              <postalCode>6999</postalCode>
              <city>Woimmer</city>
              <country>AUT</country>
            </addr>
          </serviceProviderOrganization>
        </healthCareFacility>
      </location>
    </encompassingEncounter>
  </componentOf>
  <component>
    <structuredBody>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.66"/>
          <code code="PAT_INFO_ADM" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Patienteninformation - Administrativ"/>
          <title>Weitere Patienteninformation – Administrativ</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Eigenschaft</th>
                  <th>Wert</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Versichertenkategorie</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Beruf</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
                <tr>
                  <td>Entfernung in km</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Bundeslandcode</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Erstzuweiser</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.63"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="PatInfoAdOrg" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Patienteninformation Administrativ Organizer"/>
              <statusCode code="completed"/>
              <effectiveTime>
                <low value="20220228214615+0100"/>
              </effectiveTime>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.107"/>
                  <code code="VKT" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Versichertenkategorie"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.108"/>
                  <code code="BER" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Beruf"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.109"/>
                  <code code="RZG" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.110"/>
                  <code code="EKM" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Entfernung in km"/>
                  <value nullFlavor="NA" xsi:type="PQ"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.111"/>
                  <code code="BLD" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Bundeslandcode"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.112"/>
                  <code code="ERSTZUW" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Erstzuweiser"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.67"/>
          <code code="PAT_INFO_MED" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Patienteninformation - Medizinisch"/>
          <title>Weitere Patienteninformation – Medizinisch</title>
          <component typeCode="COMP">
            <section>
              <templateId root="1.2.40.0.34.6.0.11.2.46"/>
              <templateId root="2.16.840.1.113883.10.20.1.16"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.3.25"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.1.5.3.2"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="8716-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Vital signs"/>
              <title>Vitalparameter</title>
              <text>
                <table>
                  <thead>
                    <tr>
                      <th>Vitalparameterart</th>
                      <th>Wert</th>
                      <th>Einheit</th>
                      <th>Datum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="patmed1">
                      <td ID="patmed2">Körpergröße</td>
                      <td>-</td>
                      <td>cm</td>
                      <td>-</td>
                    </tr>
                    <tr ID="patmed3">
                      <td ID="patmed4">Körpergewicht</td>
                      <td>-</td>
                      <td>kg</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <organizer classCode="CLUSTER" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.23"/>
                  <templateId extension="2015-08-01" root="2.16.840.1.113883.10.20.22.4.26"/>
                  <templateId extension="2015-11-19" root="2.16.840.1.113883.10.20.36.2"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="patmed" root="1.2.40.0.34.99.9999.6.99"/>
                  <code code="46680005" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low nullFlavor="UNK"/>
                    <high nullFlavor="UNK"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.24"/>
                      <templateId root="2.16.840.1.113883.10.20.1.31"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13.2"/>
                      <id assigningAuthorityName="Dr. Test Osteron" extension="patmed1" root="1.2.40.0.34.99.9999.7.99"/>
                      <code code="8302-2" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Körpergröße">
                        <originalText>
                          <reference value="#patmed2"/>
                        </originalText>
                      </code>
                      <text>
                        <reference value="#patmed1"/>
                      </text>
                      <statusCode code="completed"/>
                      <effectiveTime>
                        <low nullFlavor="UNK"/>
                        <high nullFlavor="UNK"/>
                      </effectiveTime>
                      <value nullFlavor="NA" unit="cm" xsi:type="PQ"/>
                    </observation>
                  </component>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.24"/>
                      <templateId root="2.16.840.1.113883.10.20.1.31"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13.2"/>
                      <id assigningAuthorityName="Dr. Test Osteron" extension="patmed3" root="1.2.40.0.34.99.9999.7.99"/>
                      <code code="3141-9" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Körpergewicht">
                        <originalText>
                          <reference value="#patmed4"/>
                        </originalText>
                      </code>
                      <text>
                        <reference value="#patmed3"/>
                      </text>
                      <statusCode code="completed"/>
                      <effectiveTime>
                        <low nullFlavor="UNK"/>
                        <high nullFlavor="UNK"/>
                      </effectiveTime>
                      <value nullFlavor="NA" unit="kg" xsi:type="PQ"/>
                    </observation>
                  </component>
                </organizer>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section>
              <templateId root="1.2.40.0.34.6.0.11.2.31"/>
              <code code="PAT_INFO_MED_MERK" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Merkmale"/>
              <title>Weitere Merkmale</title>
              <text>
                <table>
                  <thead>
                    <tr>
                      <th>Eigenschaft</th>
                      <th>Wert</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Blutgruppe</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Rhesusfaktor</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Besondere Kennzeichen/Hautfarbe</td>
                      <td ID="patmed5">-</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.65"/>
                  <code code="KOERPERLICHEMERKMALE" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Körperliche Merkmale"/>
                  <statusCode code="completed"/>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.66"/>
                      <code code="365637002" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Finding of AB0 blood group"/>
                      <value codeSystem="2.16.840.1.113883.6.96" nullFlavor="NA" xsi:type="CD"/>
                    </observation>
                  </component>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.64"/>
                      <code code="115758001" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Rh blood group phenotype (finding)"/>
                      <value codeSystem="2.16.840.1.113883.6.96" nullFlavor="NA" xsi:type="CD"/>
                    </observation>
                  </component>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.113"/>
                      <code code="BesKennzeichenHautfarbe" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Besondere Kennzeichen / Hautfarbe"/>
                      <value nullFlavor="NA" xsi:type="CD">
                        <originalText>
                          <reference value="#patmed5"/>
                        </originalText>
                      </value>
                    </observation>
                  </component>
                </organizer>
              </entry>
            </section>
          </component>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.40"/>
          <code code="BEFUNDE" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Befunde"/>
          <title>Befunde</title>
          <text/>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.96"/>
          <code code="439401001" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Diagnosis"/>
          <title>Diagnose</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Zeitraum oder Zeitpunkt</th>
                  <th>Diagnosetext</th>
                  <th>Code [Codesystem]</th>
                  <th>Diagnoseart</th>
                </tr>
              </thead>
              <tbody/>
            </table>
          </text>
        </section>
      </component>
      <component typeCode="COMP"/>
      <component/>
    </structuredBody>
  </component>
</ClinicalDocument>
`;
