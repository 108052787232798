// region federal states
/// Austrian federal states
export type FederalState = 'Vorarlberg' | 'Tirol' | 'Salzburg' | 'Kärnten' | 'Steiermark'
  | 'Oberösterreich' | 'Niederösterreich' | 'Burgenland' | 'Wien';
// endregion federal states

// region gender
/// Allowed gender values. See 1.2.40.0.34.10.4 ELGA_AdministrativeGender (DYNAMIC) in https://termpub.gesundheit.gv.at/TermBrowser/gui/main/main.zul.
export type AdministrativeGender = 'F' | 'M' | 'UN';
// endregion gender

// region id
export interface CdaId {
  root: string
  extension: string | null
  assigningAuthorityName: string | null
}
// endregion id

// region address
/// Address element in granularity 1. See https://wiki.hl7.at/index.php?title=ILF:Allgemeiner_Implementierungsleitfaden#Adress-Elemente.
export type AddressG1 = string;

/// Address element in granularity 2. See https://wiki.hl7.at/index.php?title=ILF:Allgemeiner_Implementierungsleitfaden#Adress-Elemente.
export interface AddressG2 {
  streetAddressLine: string
  postalCode: string | null
  city: string | null
  state: FederalState | null
  country: string
  additionalLocator: string | null
}

/// Address element in granularity 3. See https://wiki.hl7.at/index.php?title=ILF:Allgemeiner_Implementierungsleitfaden#Adress-Elemente.
export interface AddressG3 {
  streetName: string
  houseNumber: string
  postalCode: string | null
  city: string | null
  state: FederalState | null
  country: string
  additionalLocator: string | null
}
// endregion address

// region telecom
/// Contact data element. See https://wiki.hl7.at/index.php?title=ILF:Allgemeiner_Implementierungsleitfaden#Kontaktdaten-Elemente.
export interface Telecom {
  value: {
    type: string
    value: string
  }
  use: string | null
}
// endregion telecom

// region name
/// Name element in granularity 1. See https://wiki.hl7.at/index.php?title=ILF:Allgemeiner_Implementierungsleitfaden#Namen-Elemente.
export type NameG1 = string;

/// Name element in granularity 2. See https://wiki.hl7.at/index.php?title=ILF:Allgemeiner_Implementierungsleitfaden#Namen-Elemente.
export interface NameG2 {
  prefixes: Array<string>
  given: Array<string>
  family: Array<string>
  suffixes: Array<string>
}
// endregion name

// region lab
export interface ReferenceArea {
  low: string | null | undefined,
  high: string | null | undefined,
}

export interface LabValue {
  analysis: string | null | undefined,
  value: string | null | undefined,
  unit: string | null | undefined,
  referenceArea: ReferenceArea,
  interpretation: string | null | undefined
}
// endregion lab

// region diagnosis
export const codeSystemMap: Map<string | null | undefined, string> = new Map<string, string>([
  ['1.2.40.0.34.5.184', 'ICD-10'],
]);

export interface DiagnosisCode {
  code: string | null | undefined,
  codeSystem: string | null | undefined
}

export interface Diagnosis {
  id: number,
  time: Date,
  text: string | null | undefined,
  code?: DiagnosisCode,
  type: string | null | undefined
}
// enregion diagnosis

// region rezept
export interface Rezept {
  id: number,
  drugName: string | null | undefined,
  pharmazentralnummer: string | null | undefined,
}
// endregion rezept
