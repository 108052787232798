import { Ends2Patient } from '@/types/ends2Patient';
import {
  AdministrativeGender, codeSystemMap, Diagnosis, DiagnosisCode, FederalState, LabValue, Rezept,
} from '@/types/cdacommon';
import querySelectorAllWithHas from '@/util/polyfill-css-has';

import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

/** *
 * The default Javascript Array.from function throws a TypeError when a null value or undefined
 * value is passed to it.
 * This function wrapps the Array.from function and returns empty arrays in these cases insated.
 */
function possiblyEmptyArrayFrom(nodeList: NodeListOf<Element> | null | undefined): Array<Element> {
  if (nodeList == null) return [];

  return Array.from(nodeList);
}

export default function parse(ends2: string): Ends2Patient {
  const xmlDoc = new DOMParser().parseFromString(ends2, 'text/xml');

  /// querySelector XML

  // export const title = xmlDoc.querySelector(':root>title')!!.textContent;

  // CDAId
  const typeId = xmlDoc.querySelector(':root>id')!;
  const root = typeId.getAttribute('root')!;
  const extension = typeId.getAttribute('extension');
  const assigningAuthorityName = typeId.getAttribute('assigningAuthorityName');

  // Patient role
  // const patientRole = xmlDoc.querySelector(':root>recordTarget>patientRole>')!!;

  // Sozialversicherungsnummer
  const sozialversicherungsnummer = xmlDoc
    .querySelector(':root>recordTarget>patientRole>id[root="1.2.40.0.10.1.4.3.1"]')
    ?.getAttribute('extension');

  // Address
  // const addr = patientRole.querySelector(':root>recordTarget>patientRole>addr>')!!;
  const streetAddressLine = xmlDoc
    .querySelector(':root>recordTarget>patientRole>addr>streetAddressLine')!
    .textContent!;
  const postalCode = xmlDoc
    .querySelector(':root>recordTarget>patientRole>addr>postalCode')
    ?.textContent;
  const city = xmlDoc
    .querySelector(':root>recordTarget>patientRole>addr>city')
    ?.textContent;
  const state = <FederalState>xmlDoc
    .querySelector(':root>recordTarget>patientRole>addr>state')
    ?.textContent;
  const country = xmlDoc
    .querySelector(':root>recordTarget>patientRole>addr>country')!
    .textContent!;
  const additionalLocator = xmlDoc
    .querySelector(':root>recordTarget>patientRole>addr>additionalLocator')
    ?.textContent;

  // Telecom
  const telecoms = possiblyEmptyArrayFrom(
    xmlDoc.querySelectorAll(':root>recordTarget>patientRole>telecom'),
  )
    .map((telecom) => telecom.getAttribute('value')!);

  // Patient tag
  // const patientTag = patientRole.querySelector(':root>recordTarget>patientRole>patient')!!;

  // Name
  const prefixes = possiblyEmptyArrayFrom(
    xmlDoc.querySelectorAll(':root>recordTarget>patientRole>patient>name>prefix'),
  )
    .map((prefix) => prefix.textContent!);

  const given = possiblyEmptyArrayFrom(
    xmlDoc.querySelectorAll(':root>recordTarget>patientRole>patient>name>given'),
  )
    .map((givenElement) => givenElement.textContent!);

  const family = possiblyEmptyArrayFrom(
    xmlDoc.querySelectorAll(':root>recordTarget>patientRole>patient>name>family'),
  )
    .map((familyElement) => familyElement.textContent!);

  const suffixes = possiblyEmptyArrayFrom(
    xmlDoc.querySelectorAll(':root>recordTarget>patientRole>patient>name>suffix'),
  )
    .map((suffix) => suffix.textContent!);

  // Gender
  const gender = <AdministrativeGender | null>xmlDoc
    .querySelector(':root>recordTarget>patientRole>patient>administrativeGenderCode')
    ?.getAttribute('code');

  // Date of birth
  const birthTimeString = xmlDoc.querySelector(':root>recordTarget>patientRole>patient>birthTime')
    ?.getAttribute('value');
  const birthTime = dayjs(birthTimeString, 'YYYYMMDD').toDate();

  // Lab Values
  const labValues: Array<LabValue> = [];
  try {
    const labValuesSection = querySelectorAllWithHas(
      ':root>component>structuredBody>component>section:has(>templateId[root="1.2.40.0.34.6.0.11.2.104"])',
      xmlDoc,
    )[0];

    const observations = labValuesSection.querySelectorAll('observation');

    observations.forEach((obs) => {
      const analysisTag = obs.querySelector('code');
      const valueTag = obs.querySelector('value');
      const referenceAreaLowTag = obs.querySelector('referenceRange>observationRange>value>low');
      const referenceAreaHighTag = obs.querySelector('referenceRange>observationRange>value>high');
      const interpretationTag = obs.querySelector('interpretationCode');

      labValues.push({
        analysis: analysisTag?.getAttribute('displayName'),
        value: valueTag?.getAttribute('value'),
        unit: valueTag?.getAttribute('unit'),
        referenceArea: {
          low: referenceAreaLowTag?.getAttribute('value'),
          high: referenceAreaHighTag?.getAttribute('value'),
        },
        interpretation: interpretationTag?.getAttribute('displayName'),
      });
    });
  } catch (e) {
    console.log(`No lab values for patient ${given[0]} ${family[0]}`);
  }

  // Diagnosis
  const diagnosesElement = querySelectorAllWithHas(
    ':root>component>structuredBody>component>section:has(>templateId[root="1.2.40.0.34.6.0.11.2.96"])',
    xmlDoc,
  );
  const diagnosisLvl3 = diagnosesElement[0].querySelectorAll('entry>act');

  const diagnoses: Array<Diagnosis> = Array.from(diagnosisLvl3)
    .map((diagnosis) => {
      const time = dayjs(
        diagnosis.querySelector('effectiveTime>low')?.getAttribute('value'),
        'YYYYMMDD',
      ).toDate();

      const referenceId = diagnosis
        ?.querySelector('entryRelationship>observation>code>originalText>reference')
        ?.getAttribute('value')
        ?.substr(1);
      const referencedTag = diagnosesElement[0].querySelector(`[ID="${referenceId}"]`);
      const text = referencedTag?.innerHTML;

      const codeElement = diagnosis.querySelector('entryRelationship>observation>value');
      let code: DiagnosisCode | undefined;
      if (codeElement?.getAttribute('code') != null) {
        code = {
          code: codeElement?.getAttribute('code'),
          codeSystem: codeSystemMap.get(codeElement?.getAttribute('codeSystem')),
        };
      } else {
        const level2Code = diagnosesElement[0].querySelector(`td[ID="${referenceId}"] + td`)
          ?.innerHTML;

        if (level2Code == null || /\w+ \[.*\]/i.exec(level2Code) == null) {
          code = {
            code: level2Code,
            codeSystem: level2Code,
          };
        } else {
          const level2CodeParts = level2Code!.split(' ');
          code = {
            code: level2CodeParts[0],
            codeSystem: level2CodeParts[1].slice(1, -1),
          };
        }
      }

      const type = codeElement
        ?.querySelector('qualifier>value')
        ?.getAttribute('displayName');

      return {
        time, text, code, type, id: Math.random() * 1000,
      };
    });

  // Rezepte
  const rezepteElements = querySelectorAllWithHas(
    ':root>component>structuredBody>component>section:has(>templateId[root="1.2.40.0.34.6.0.11.2.101"])',
    xmlDoc,
  );
  const substanceAdministrationTags = rezepteElements
    .flatMap((section) => Array.from(section.querySelectorAll('entry>substanceAdministration')));

  const rezepte: Array<Rezept> = substanceAdministrationTags
    .map((entry) => ({
      id: Math.random() * 1000,
      drugName: entry.querySelector('name')?.innerHTML,
      pharmazentralnummer: entry
        .querySelector('consumable>manufacturedProduct>manufacturedMaterial>code')
        ?.getAttribute('code'),
    }));

  return {
    uniqueId: `${root} ${extension} ${assigningAuthorityName} ${Math.random() * 100}`,
    id: {
      root,
      extension,
      assigningAuthorityName,
    },
    sozialversicherungsnummer: sozialversicherungsnummer ?? null,
    address: {
      streetAddressLine,
      postalCode: postalCode ?? null,
      city: city ?? null,
      state,
      country,
      additionalLocator: additionalLocator ?? null,
    },
    telecoms,
    name: {
      prefixes,
      given,
      family,
      suffixes,
    },
    gender,
    birthTime,
    labValues,
    diagnoses,
    rezepte,
    ebmEvaluations: [],
  };
}
