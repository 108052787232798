/* eslint-disable */
export const pat00007 = `
<?xml-stylesheet type="text/xsl" href="ELGA_Stylesheet.xsl"?>
<ClinicalDocument xmlns:hl7at="urn:hl7-at:v3" xmlns:pharm="urn:ihe:pharm:medication" xmlns:sdtc="urn:hl7-org:sdtc" xmlns="urn:hl7-org:v3" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <realmCode code="AT"/>
  <typeId extension="POCD_HD000040" root="2.16.840.1.113883.1.3"/>
  <templateId root="1.2.40.0.34.6.0.11.0.1"/>
  <templateId root="1.2.40.0.34.7.25.1"/>
  <templateId root="1.2.40.0.34.6.0.11.0.6"/>
  <id assigningAuthorityName="Dr. Test Osteron" extension="1" root="1.2.40.0.34.99.9999"/>
  <code code="EXNDS_Patientendaten" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Datenbankexport EXNDS - Patientendaten">
    <translation code="DatenbankexportEXNDS" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Datenbankexport EXNDS"/>
  </code>
  <title>Datenbankexport</title>
  <hl7at:terminologyDate value="20220101"/>
  <hl7at:formatCode code="urn:hl7-at:exnds-patient:2021"/>
  <hl7at:practiceSettingCode code="F001" codeSystem="1.2.40.0.34.5.12"/>
  <effectiveTime value="20220228214615+0100"/>
  <confidentialityCode code="N" codeSystem="2.16.840.1.113883.5.25" codeSystemName="HL7:Confidentiality" displayName="normal"/>
  <languageCode code="de-AT"/>
  <setId assigningAuthorityName="Dr. Test Osteron" extension="1" root="1.2.40.0.34.99.9999"/>
  <versionNumber value="1"/>
  <recordTarget>
    <patientRole>
      <id assigningAuthorityName="Dr. Test Osteron" extension="PAT00007" root="1.2.40.0.34.99.9999.1.2"/>
      <id assigningAuthorityName="Österreichische Sozialversicherung" nullFlavor="UNK" root="1.2.40.0.10.1.4.3.1"/>
      <addr use="H">
        <streetAddressLine></streetAddressLine>
        <postalCode></postalCode>
        <city></city>
        <country>AUT</country>
      </addr>
      <patient>
        <name>
          <given>Max</given>
          <family>Mustermann</family>
        </name>
        <administrativeGenderCode code="M" codeSystem="2.16.840.1.113883.5.1" codeSystemName="HL7:AdministrativeGender" displayName="Männliche Person"/>
        <birthTime value="19240129"/>
      </patient>
    </patientRole>
  </recordTarget>
  <author>
    <functionCode code="OL" codeSystem="1.2.40.0.34.99.9999.10.1" codeSystemName="doctory.elga.cda.Funktionscodes" displayName="Ärztliche Ordinationsleitung"/>
    <time value="20220228214615+0100"/>
    <assignedAuthor>
      <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
      <code code="107" codeSystem="1.2.40.0.34.5.160" codeSystemName="gda-rollen" displayName="Fachärztin/Facharzt für Chirurgie"/>
      <telecom use="WP" value="mailto:max@muster.com"/>
      <assignedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </assignedPerson>
      <representedOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedOrganization>
    </assignedAuthor>
  </author>
  <custodian>
    <assignedCustodian>
      <representedCustodianOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedCustodianOrganization>
    </assignedCustodian>
  </custodian>
  <legalAuthenticator>
    <time value="20220228214615+0100"/>
    <signatureCode code="S"/>
    <assignedEntity>
      <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
      <telecom use="WP" value="mailto:max@muster.com"/>
      <assignedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </assignedPerson>
      <representedOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedOrganization>
    </assignedEntity>
  </legalAuthenticator>
  <participant typeCode="CALLBCK">
    <templateId root="1.2.40.0.34.6.0.11.1.20"/>
    <associatedEntity classCode="PROV">
      <telecom use="WP" value="mailto:max@muster.com"/>
      <associatedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </associatedPerson>
      <scopingOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </scopingOrganization>
    </associatedEntity>
  </participant>
  <componentOf>
    <encompassingEncounter>
      <code code="AMB" codeSystem="2.16.840.1.113883.5.4" codeSystemName="HL7:ActCode" displayName="ambulant"/>
      <effectiveTime>
        <low value=""/>
        <high value=""/>
      </effectiveTime>
      <responsibleParty>
        <assignedEntity>
          <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
          <telecom use="WP" value="mailto:max@muster.com"/>
          <assignedPerson>
            <name>
              <prefix>Dr.</prefix>
              <given>Test</given>
              <family>Osteron</family>
            </name>
          </assignedPerson>
        </assignedEntity>
      </responsibleParty>
      <location>
        <healthCareFacility>
          <code code="158" codeSystem="1.2.40.0.34.5.2" displayName="Fachärztin/Facharzt"/>
          <serviceProviderOrganization>
            <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
            <name>Dr. Test Osteron</name>
            <telecom value="mailto:max@muster.com"/>
            <telecom value="www.osteron-praxis.at"/>
            <addr>
              <streetAddressLine>Im Walde 55</streetAddressLine>
              <postalCode>6999</postalCode>
              <city>Woimmer</city>
              <country>AUT</country>
            </addr>
          </serviceProviderOrganization>
        </healthCareFacility>
      </location>
    </encompassingEncounter>
  </componentOf>
  <component>
    <structuredBody>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.66"/>
          <code code="PAT_INFO_ADM" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Patienteninformation - Administrativ"/>
          <title>Weitere Patienteninformation – Administrativ</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Eigenschaft</th>
                  <th>Wert</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Versichertenkategorie</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Beruf</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
                <tr>
                  <td>Entfernung in km</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Bundeslandcode</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Erstzuweiser</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.63"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="PatInfoAdOrg" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Patienteninformation Administrativ Organizer"/>
              <statusCode code="completed"/>
              <effectiveTime>
                <low value="20220228214615+0100"/>
              </effectiveTime>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.107"/>
                  <code code="VKT" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Versichertenkategorie"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.108"/>
                  <code code="BER" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Beruf"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.109"/>
                  <code code="RZG" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.110"/>
                  <code code="EKM" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Entfernung in km"/>
                  <value nullFlavor="NA" xsi:type="PQ"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.111"/>
                  <code code="BLD" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Bundeslandcode"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.112"/>
                  <code code="ERSTZUW" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Erstzuweiser"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.67"/>
          <code code="PAT_INFO_MED" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Patienteninformation - Medizinisch"/>
          <title>Weitere Patienteninformation – Medizinisch</title>
          <component typeCode="COMP">
            <section>
              <templateId root="1.2.40.0.34.6.0.11.2.46"/>
              <templateId root="2.16.840.1.113883.10.20.1.16"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.3.25"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.1.5.3.2"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="8716-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Vital signs"/>
              <title>Vitalparameter</title>
              <text>
                <table>
                  <thead>
                    <tr>
                      <th>Vitalparameterart</th>
                      <th>Wert</th>
                      <th>Einheit</th>
                      <th>Datum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="patmed1">
                      <td ID="patmed2">Körpergröße</td>
                      <td>-</td>
                      <td>cm</td>
                      <td>-</td>
                    </tr>
                    <tr ID="patmed3">
                      <td ID="patmed4">Körpergewicht</td>
                      <td>-</td>
                      <td>kg</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <organizer classCode="CLUSTER" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.23"/>
                  <templateId extension="2015-08-01" root="2.16.840.1.113883.10.20.22.4.26"/>
                  <templateId extension="2015-11-19" root="2.16.840.1.113883.10.20.36.2"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="patmed" root="1.2.40.0.34.99.9999.6.99"/>
                  <code code="46680005" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low nullFlavor="UNK"/>
                    <high nullFlavor="UNK"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.24"/>
                      <templateId root="2.16.840.1.113883.10.20.1.31"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13.2"/>
                      <id assigningAuthorityName="Dr. Test Osteron" extension="patmed1" root="1.2.40.0.34.99.9999.7.99"/>
                      <code code="8302-2" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Körpergröße">
                        <originalText>
                          <reference value="#patmed2"/>
                        </originalText>
                      </code>
                      <text>
                        <reference value="#patmed1"/>
                      </text>
                      <statusCode code="completed"/>
                      <effectiveTime>
                        <low nullFlavor="UNK"/>
                        <high nullFlavor="UNK"/>
                      </effectiveTime>
                      <value nullFlavor="NA" unit="cm" xsi:type="PQ"/>
                    </observation>
                  </component>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.24"/>
                      <templateId root="2.16.840.1.113883.10.20.1.31"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13.2"/>
                      <id assigningAuthorityName="Dr. Test Osteron" extension="patmed3" root="1.2.40.0.34.99.9999.7.99"/>
                      <code code="3141-9" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Körpergewicht">
                        <originalText>
                          <reference value="#patmed4"/>
                        </originalText>
                      </code>
                      <text>
                        <reference value="#patmed3"/>
                      </text>
                      <statusCode code="completed"/>
                      <effectiveTime>
                        <low nullFlavor="UNK"/>
                        <high nullFlavor="UNK"/>
                      </effectiveTime>
                      <value nullFlavor="NA" unit="kg" xsi:type="PQ"/>
                    </observation>
                  </component>
                </organizer>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section>
              <templateId root="1.2.40.0.34.6.0.11.2.31"/>
              <code code="PAT_INFO_MED_MERK" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Merkmale"/>
              <title>Weitere Merkmale</title>
              <text>
                <table>
                  <thead>
                    <tr>
                      <th>Eigenschaft</th>
                      <th>Wert</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Blutgruppe</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Rhesusfaktor</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Besondere Kennzeichen/Hautfarbe</td>
                      <td ID="patmed5">-</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.65"/>
                  <code code="KOERPERLICHEMERKMALE" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Körperliche Merkmale"/>
                  <statusCode code="completed"/>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.66"/>
                      <code code="365637002" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Finding of AB0 blood group"/>
                      <value codeSystem="2.16.840.1.113883.6.96" nullFlavor="NA" xsi:type="CD"/>
                    </observation>
                  </component>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.64"/>
                      <code code="115758001" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Rh blood group phenotype (finding)"/>
                      <value codeSystem="2.16.840.1.113883.6.96" nullFlavor="NA" xsi:type="CD"/>
                    </observation>
                  </component>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.113"/>
                      <code code="BesKennzeichenHautfarbe" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Besondere Kennzeichen / Hautfarbe"/>
                      <value nullFlavor="NA" xsi:type="CD">
                        <originalText>
                          <reference value="#patmed5"/>
                        </originalText>
                      </value>
                    </observation>
                  </component>
                </organizer>
              </entry>
            </section>
          </component>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.40"/>
          <code code="BEFUNDE" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Befunde"/>
          <title>Befunde</title>
          <text/>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.96"/>
          <code code="439401001" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Diagnosis"/>
          <title>Diagnose</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Zeitraum oder Zeitpunkt</th>
                  <th>Diagnosetext</th>
                  <th>Code [Codesystem]</th>
                  <th>Diagnoseart</th>
                </tr>
              </thead>
              <tbody/>
            </table>
          </text>
        </section>
      </component>
      <component typeCode="COMP">
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 30.07.1999</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab1">
                      <td>Ferritin</td>
                      <td>75.0</td>
                      <td>ug/L</td>
                      <td ID="lab2">30-350</td>
                      <td></td>
                    </tr>
                    <tr ID="lab3">
                      <td>C-reaktives Protein (CRP)</td>
                      <td>0.7</td>
                      <td>mg/L</td>
                      <td ID="lab4">-1.0</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab1" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab1"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="19990730000000+0100"/>
                          <value unit="ug/L" value="75.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab2"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab3" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="1988-5" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="CRP"/>
                          <text>
                            <reference value="#lab3"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="19990730000000+0100"/>
                          <value unit="mg/L" value="0.7" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab4"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/L" value=""/>
                                <high unit="mg/L" value="1.0"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Hormone</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab5">
                      <td>Thyreoidea-stimulierendes Hormon (TSH)</td>
                      <td>0.81</td>
                      <td>u[iU]/mL</td>
                      <td ID="lab6">0.5-3.5</td>
                      <td></td>
                    </tr>
                    <tr ID="lab7">
                      <td>TSH Delta (TRH-Test)</td>
                      <td>euthyreoter Bereich</td>
                      <td>u[iU]/mL</td>
                      <td ID="lab8"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <paragraph styleCode="xELGA_h3">Tumormarker</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab9">
                      <td>PSA(Prostata-spezifisches Antigen)</td>
                      <td>3.34</td>
                      <td>ug/L</td>
                      <td ID="lab10">-4.0</td>
                      <td></td>
                    </tr>
                    <tr ID="lab11">
                      <td>Freies PSA (Prostata-spezifisches Antigen)</td>
                      <td>3.0</td>
                      <td>ug/L</td>
                      <td ID="lab12"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06330" codeSystem="1.2.40.0.34.5.11" displayName="Hormone"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab5" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="3016-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TSH"/>
                          <text>
                            <reference value="#lab5"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="19990730000000+0100"/>
                          <value unit="u[iU]/mL" value="0.81" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab6"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="u[iU]/mL" value="0.5"/>
                                <high unit="u[iU]/mL" value="3.5"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab7" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="34054-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TSHT"/>
                          <text>
                            <reference value="#lab7"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="19990730000000+0100"/>
                          <value unit="u[iU]/mL" value="euthyreoter Bereich" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab8"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06350" codeSystem="1.2.40.0.34.5.11" displayName="Tumormarker"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab9" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2857-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="PSA"/>
                          <text>
                            <reference value="#lab9"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="19990730000000+0100"/>
                          <value unit="ug/L" value="3.34" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab10"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value=""/>
                                <high unit="ug/L" value="4.0"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab11" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="10886-0" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FPSA"/>
                          <text>
                            <reference value="#lab11"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="19990730000000+0100"/>
                          <value unit="ug/L" value="3.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab12"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="2500" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
              <title>Sonstige</title>
              <text>
                <paragraph styleCode="xELGA_h3">Sonstige</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab13">
                      <td>PSATX</td>
                      <td>5.0</td>
                      <td></td>
                      <td ID="lab14"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="2500" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="17890" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab13" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="" codeSystem="1.2.40.0.34.99.9999.4.4" codeSystemName="doctory_lab" displayName="PSATX"/>
                          <text>
                            <reference value="#lab13"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="19990730000000+0100"/>
                          <value unit="" value="5.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab14"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 01.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab15">
                      <td>Ferritin</td>
                      <td>170.0</td>
                      <td>ug/L</td>
                      <td ID="lab16">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab15" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab15"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120601000000+0100"/>
                          <value unit="ug/L" value="170.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab16"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Hormone</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab17">
                      <td>Thyreoidea-stimulierendes Hormon (TSH)</td>
                      <td>1.06</td>
                      <td>u[iU]/mL</td>
                      <td ID="lab18">0.5-3.5</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <paragraph styleCode="xELGA_h3">Tumormarker</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab19">
                      <td>PSA(Prostata-spezifisches Antigen)</td>
                      <td>4.4</td>
                      <td>ug/L</td>
                      <td ID="lab20">-4.0</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06330" codeSystem="1.2.40.0.34.5.11" displayName="Hormone"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab17" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="3016-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TSH"/>
                          <text>
                            <reference value="#lab17"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120601000000+0100"/>
                          <value unit="u[iU]/mL" value="1.06" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab18"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="u[iU]/mL" value="0.5"/>
                                <high unit="u[iU]/mL" value="3.5"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06350" codeSystem="1.2.40.0.34.5.11" displayName="Tumormarker"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab19" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2857-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="PSA"/>
                          <text>
                            <reference value="#lab19"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120601000000+0100"/>
                          <value unit="ug/L" value="4.4" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab20"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value=""/>
                                <high unit="ug/L" value="4.0"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 02.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab21">
                      <td>Ferritin</td>
                      <td>171.0</td>
                      <td>ug/L</td>
                      <td ID="lab22">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab21" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab21"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120602000000+0100"/>
                          <value unit="ug/L" value="171.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab22"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Tumormarker</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab23">
                      <td>PSA(Prostata-spezifisches Antigen)</td>
                      <td>4.3</td>
                      <td>ug/L</td>
                      <td ID="lab24">-4.0</td>
                      <td></td>
                    </tr>
                    <tr ID="lab25">
                      <td>Freies PSA (Prostata-spezifisches Antigen)</td>
                      <td>3.0</td>
                      <td>ug/L</td>
                      <td ID="lab26"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06350" codeSystem="1.2.40.0.34.5.11" displayName="Tumormarker"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab23" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2857-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="PSA"/>
                          <text>
                            <reference value="#lab23"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120602000000+0100"/>
                          <value unit="ug/L" value="4.3" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab24"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value=""/>
                                <high unit="ug/L" value="4.0"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab25" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="10886-0" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FPSA"/>
                          <text>
                            <reference value="#lab25"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120602000000+0100"/>
                          <value unit="ug/L" value="3.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab26"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 04.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab27">
                      <td>Ferritin</td>
                      <td>173.0</td>
                      <td>ug/L</td>
                      <td ID="lab28">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab27" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab27"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120604000000+0100"/>
                          <value unit="ug/L" value="173.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab28"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Hormone</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab29">
                      <td>Thyreoidea-stimulierendes Hormon (TSH)</td>
                      <td>1.1</td>
                      <td>u[iU]/mL</td>
                      <td ID="lab30">0.5-3.5</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06330" codeSystem="1.2.40.0.34.5.11" displayName="Hormone"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab29" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="3016-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TSH"/>
                          <text>
                            <reference value="#lab29"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120604000000+0100"/>
                          <value unit="u[iU]/mL" value="1.1" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab30"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="u[iU]/mL" value="0.5"/>
                                <high unit="u[iU]/mL" value="3.5"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 05.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab31">
                      <td>Ferritin</td>
                      <td>174.0</td>
                      <td>ug/L</td>
                      <td ID="lab32">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab31" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab31"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120605000000+0100"/>
                          <value unit="ug/L" value="174.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab32"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Hormone</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab33">
                      <td>Thyreoidea-stimulierendes Hormon (TSH)</td>
                      <td>1.05</td>
                      <td>u[iU]/mL</td>
                      <td ID="lab34">0.5-3.5</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <paragraph styleCode="xELGA_h3">Tumormarker</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab35">
                      <td>Freies PSA (Prostata-spezifisches Antigen)</td>
                      <td>4.0</td>
                      <td>ug/L</td>
                      <td ID="lab36"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06330" codeSystem="1.2.40.0.34.5.11" displayName="Hormone"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab33" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="3016-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TSH"/>
                          <text>
                            <reference value="#lab33"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120605000000+0100"/>
                          <value unit="u[iU]/mL" value="1.05" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab34"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="u[iU]/mL" value="0.5"/>
                                <high unit="u[iU]/mL" value="3.5"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06350" codeSystem="1.2.40.0.34.5.11" displayName="Tumormarker"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab35" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="10886-0" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FPSA"/>
                          <text>
                            <reference value="#lab35"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120605000000+0100"/>
                          <value unit="ug/L" value="4.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab36"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 06.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab37">
                      <td>Ferritin</td>
                      <td>175.0</td>
                      <td>ug/L</td>
                      <td ID="lab38">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab37" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab37"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120606000000+0100"/>
                          <value unit="ug/L" value="175.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab38"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Tumormarker</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab39">
                      <td>PSA(Prostata-spezifisches Antigen)</td>
                      <td>4.1</td>
                      <td>ug/L</td>
                      <td ID="lab40">-4.0</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06350" codeSystem="1.2.40.0.34.5.11" displayName="Tumormarker"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab39" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2857-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="PSA"/>
                          <text>
                            <reference value="#lab39"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120606000000+0100"/>
                          <value unit="ug/L" value="4.1" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab40"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value=""/>
                                <high unit="ug/L" value="4.0"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 07.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab41">
                      <td>Ferritin</td>
                      <td>175.0</td>
                      <td>ug/L</td>
                      <td ID="lab42">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab41" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab41"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120607000000+0100"/>
                          <value unit="ug/L" value="175.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab42"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Hormone</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab43" styleCode="xELGA_red">
                      <td>Thyreoidea-stimulierendes Hormon (TSH)</td>
                      <td>4.1</td>
                      <td>u[iU]/mL</td>
                      <td ID="lab44">0.5-3.5</td>
                      <td>+</td>
                    </tr>
                  </tbody>
                </table>
                <paragraph styleCode="xELGA_h3">Tumormarker</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab45" styleCode="xELGA_red">
                      <td>Freies PSA (Prostata-spezifisches Antigen)</td>
                      <td>4.0</td>
                      <td>ug/L</td>
                      <td ID="lab46"></td>
                      <td>++++</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06330" codeSystem="1.2.40.0.34.5.11" displayName="Hormone"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab43" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="3016-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TSH"/>
                          <text>
                            <reference value="#lab43"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120607000000+0100"/>
                          <value unit="u[iU]/mL" value="4.1" xsi:type="PQ"/>
                          <interpretationCode code="H" codeSystem="2.16.840.1.113883.5.83" displayName="oberhalb des Referenzbereiches"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab44"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="u[iU]/mL" value="0.5"/>
                                <high unit="u[iU]/mL" value="3.5"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06350" codeSystem="1.2.40.0.34.5.11" displayName="Tumormarker"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab45" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="10886-0" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FPSA"/>
                          <text>
                            <reference value="#lab45"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120607000000+0100"/>
                          <value unit="ug/L" value="4.0" xsi:type="PQ"/>
                          <interpretationCode code="HH" codeSystem="2.16.840.1.113883.5.83" displayName="oberhalb des Alarmbereiches"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab46"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 08.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab47">
                      <td>Ferritin</td>
                      <td>176.0</td>
                      <td>ug/L</td>
                      <td ID="lab48">30-350</td>
                      <td></td>
                    </tr>
                    <tr ID="lab49">
                      <td>Ferritin</td>
                      <td>176.0</td>
                      <td>ug/L</td>
                      <td ID="lab50">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab47" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab47"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120608000000+0100"/>
                          <value unit="ug/L" value="176.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab48"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab49" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab49"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120608000000+0100"/>
                          <value unit="ug/L" value="176.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab50"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Tumormarker</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab51" styleCode="xELGA_red">
                      <td>PSA(Prostata-spezifisches Antigen)</td>
                      <td>0.33</td>
                      <td>ug/L</td>
                      <td ID="lab52">-4.0</td>
                      <td>--</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06350" codeSystem="1.2.40.0.34.5.11" displayName="Tumormarker"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab51" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2857-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="PSA"/>
                          <text>
                            <reference value="#lab51"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120608000000+0100"/>
                          <value unit="ug/L" value="0.33" xsi:type="PQ"/>
                          <interpretationCode code="LL" codeSystem="2.16.840.1.113883.5.83" displayName="unterhalb des Alarmbereiches"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab52"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value=""/>
                                <high unit="ug/L" value="4.0"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="2500" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
              <title>Sonstige</title>
              <text>
                <paragraph styleCode="xELGA_h3">Sonstige</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab53">
                      <td>PSATX</td>
                      <td>5.0</td>
                      <td></td>
                      <td ID="lab54"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="2500" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="17890" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab53" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="" codeSystem="1.2.40.0.34.99.9999.4.4" codeSystemName="doctory_lab" displayName="PSATX"/>
                          <text>
                            <reference value="#lab53"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120608000000+0100"/>
                          <value unit="" value="5.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab54"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 05.07.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab55">
                      <td>Ferritin</td>
                      <td>187.0</td>
                      <td>ug/L</td>
                      <td ID="lab56">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab55" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab55"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120705000000+0100"/>
                          <value unit="ug/L" value="187.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab56"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 08.07.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab57">
                      <td>Ferritin</td>
                      <td>190.0</td>
                      <td>ug/L</td>
                      <td ID="lab58">30-350</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab57" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2276-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FER"/>
                          <text>
                            <reference value="#lab57"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120708000000+0100"/>
                          <value unit="ug/L" value="190.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab58"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value="30"/>
                                <high unit="ug/L" value="350"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
              <title>Hormone/Vitamine/Tumormarker</title>
              <text>
                <paragraph styleCode="xELGA_h3">Hormone</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab59">
                      <td>Thyreoidea-stimulierendes Hormon (TSH)</td>
                      <td>3.5</td>
                      <td>u[iU]/mL</td>
                      <td ID="lab60">0.5-3.5</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <paragraph styleCode="xELGA_h3">Tumormarker</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab61">
                      <td>PSA(Prostata-spezifisches Antigen)</td>
                      <td>0.35</td>
                      <td>ug/L</td>
                      <td ID="lab62">-4.0</td>
                      <td></td>
                    </tr>
                    <tr ID="lab63">
                      <td>Freies PSA (Prostata-spezifisches Antigen)</td>
                      <td>3.5</td>
                      <td>ug/L</td>
                      <td ID="lab64"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="600" codeSystem="1.2.40.0.34.5.11" displayName="Hormone/Vitamine/Tumormarker"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06330" codeSystem="1.2.40.0.34.5.11" displayName="Hormone"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab59" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="3016-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TSH"/>
                          <text>
                            <reference value="#lab59"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120708000000+0100"/>
                          <value unit="u[iU]/mL" value="3.5" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab60"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="u[iU]/mL" value="0.5"/>
                                <high unit="u[iU]/mL" value="3.5"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="06350" codeSystem="1.2.40.0.34.5.11" displayName="Tumormarker"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab61" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2857-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="PSA"/>
                          <text>
                            <reference value="#lab61"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120708000000+0100"/>
                          <value unit="ug/L" value="0.35" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab62"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="ug/L" value=""/>
                                <high unit="ug/L" value="4.0"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab63" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="10886-0" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="FPSA"/>
                          <text>
                            <reference value="#lab63"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120708000000+0100"/>
                          <value unit="ug/L" value="3.5" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab64"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
      </component>
      <component/>
    </structuredBody>
  </component>
</ClinicalDocument>
`;
