/* eslint-disable */
export const pat00001 = `
<?xml-stylesheet type="text/xsl" href="ELGA_Stylesheet.xsl"?>
<ClinicalDocument xmlns:hl7at="urn:hl7-at:v3" xmlns:pharm="urn:ihe:pharm:medication" xmlns:sdtc="urn:hl7-org:sdtc" xmlns="urn:hl7-org:v3" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <realmCode code="AT"/>
  <typeId extension="POCD_HD000040" root="2.16.840.1.113883.1.3"/>
  <templateId root="1.2.40.0.34.6.0.11.0.1"/>
  <templateId root="1.2.40.0.34.7.25.1"/>
  <templateId root="1.2.40.0.34.6.0.11.0.6"/>
  <id assigningAuthorityName="Dr. Test Osteron" extension="1" root="1.2.40.0.34.99.9999"/>
  <code code="EXNDS_Patientendaten" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Datenbankexport EXNDS - Patientendaten">
    <translation code="DatenbankexportEXNDS" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Datenbankexport EXNDS"/>
  </code>
  <title>Datenbankexport</title>
  <hl7at:terminologyDate value="20220101"/>
  <hl7at:formatCode code="urn:hl7-at:exnds-patient:2021"/>
  <hl7at:practiceSettingCode code="F001" codeSystem="1.2.40.0.34.5.12"/>
  <effectiveTime value="20220228214615+0100"/>
  <confidentialityCode code="N" codeSystem="2.16.840.1.113883.5.25" codeSystemName="HL7:Confidentiality" displayName="normal"/>
  <languageCode code="de-AT"/>
  <setId assigningAuthorityName="Dr. Test Osteron" extension="1" root="1.2.40.0.34.99.9999"/>
  <versionNumber value="1"/>
  <recordTarget>
    <patientRole>
      <id assigningAuthorityName="Dr. Test Osteron" extension="PAT00001" root="1.2.40.0.34.99.9999.1.2"/>
      <id assigningAuthorityName="Österreichische Sozialversicherung" extension="2037090969" root="1.2.40.0.10.1.4.3.1"/>
      <addr use="H">
        <streetAddressLine>Keinweg 99</streetAddressLine>
        <postalCode>9999</postalCode>
        <city>Irgendwo</city>
        <country>AUT</country>
      </addr>
      <telecom use="H" value="tel:08 15 4711"/>
      <telecom value="mailto:apos@example.gr"/>
      <patient>
        <name>
          <given>Apos</given>
          <family>Apokolokynthesis</family>
        </name>
        <administrativeGenderCode code="M" codeSystem="2.16.840.1.113883.5.1" codeSystemName="HL7:AdministrativeGender" displayName="Männliche Person"/>
        <birthTime value="19690909"/>
      </patient>
    </patientRole>
  </recordTarget>
  <author>
    <functionCode code="OL" codeSystem="1.2.40.0.34.99.9999.10.1" codeSystemName="doctory.elga.cda.Funktionscodes" displayName="Ärztliche Ordinationsleitung"/>
    <time value="20220228214615+0100"/>
    <assignedAuthor>
      <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
      <code code="107" codeSystem="1.2.40.0.34.5.160" codeSystemName="gda-rollen" displayName="Fachärztin/Facharzt für Chirurgie"/>
      <telecom use="WP" value="mailto:max@muster.com"/>
      <assignedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </assignedPerson>
      <representedOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedOrganization>
    </assignedAuthor>
  </author>
  <custodian>
    <assignedCustodian>
      <representedCustodianOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedCustodianOrganization>
    </assignedCustodian>
  </custodian>
  <legalAuthenticator>
    <time value="20220228214615+0100"/>
    <signatureCode code="S"/>
    <assignedEntity>
      <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
      <telecom use="WP" value="mailto:max@muster.com"/>
      <assignedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </assignedPerson>
      <representedOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedOrganization>
    </assignedEntity>
  </legalAuthenticator>
  <participant typeCode="CALLBCK">
    <templateId root="1.2.40.0.34.6.0.11.1.20"/>
    <associatedEntity classCode="PROV">
      <telecom use="WP" value="mailto:max@muster.com"/>
      <associatedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </associatedPerson>
      <scopingOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </scopingOrganization>
    </associatedEntity>
  </participant>
  <inFulfillmentOf typeCode="FLFS">
    <order classCode="ACT" moodCode="RQO">
      <id nullFlavor="UNK"/>
    </order>
  </inFulfillmentOf>
  <componentOf>
    <encompassingEncounter>
      <code code="AMB" codeSystem="2.16.840.1.113883.5.4" codeSystemName="HL7:ActCode" displayName="ambulant"/>
      <effectiveTime>
        <low value=""/>
        <high value=""/>
      </effectiveTime>
      <responsibleParty>
        <assignedEntity>
          <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
          <telecom use="WP" value="mailto:max@muster.com"/>
          <assignedPerson>
            <name>
              <prefix>Dr.</prefix>
              <given>Test</given>
              <family>Osteron</family>
            </name>
          </assignedPerson>
        </assignedEntity>
      </responsibleParty>
      <location>
        <healthCareFacility>
          <code code="158" codeSystem="1.2.40.0.34.5.2" displayName="Fachärztin/Facharzt"/>
          <serviceProviderOrganization>
            <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
            <name>Dr. Test Osteron</name>
            <telecom value="mailto:max@muster.com"/>
            <telecom value="www.osteron-praxis.at"/>
            <addr>
              <streetAddressLine>Im Walde 55</streetAddressLine>
              <postalCode>6999</postalCode>
              <city>Woimmer</city>
              <country>AUT</country>
            </addr>
          </serviceProviderOrganization>
        </healthCareFacility>
      </location>
    </encompassingEncounter>
  </componentOf>
  <component>
    <structuredBody>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.66"/>
          <code code="PAT_INFO_ADM" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Patienteninformation - Administrativ"/>
          <title>Weitere Patienteninformation – Administrativ</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Eigenschaft</th>
                  <th>Wert</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Versichertenkategorie</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Beruf</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
                <tr>
                  <td>Entfernung in km</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Bundeslandcode</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Erstzuweiser</td>
                  <td ID="patverw1">Dr.med. Dagmar Mayrhofer-Schmid</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.63"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="PatInfoAdOrg" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Patienteninformation Administrativ Organizer"/>
              <statusCode code="completed"/>
              <effectiveTime>
                <low value="20220228214615+0100"/>
              </effectiveTime>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.107"/>
                  <code code="VKT" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Versichertenkategorie"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.108"/>
                  <code code="BER" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Beruf"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.109"/>
                  <code code="RZG" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.110"/>
                  <code code="EKM" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Entfernung in km"/>
                  <value nullFlavor="NA" xsi:type="PQ"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.111"/>
                  <code code="BLD" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Bundeslandcode"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.112"/>
                  <code code="ERSTZUW" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Erstzuweiser"/>
                  <text>
                    <reference value="#patverw1"/>
                  </text>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.67"/>
          <code code="PAT_INFO_MED" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Patienteninformation - Medizinisch"/>
          <title>Weitere Patienteninformation – Medizinisch</title>
          <component typeCode="COMP">
            <section>
              <templateId root="1.2.40.0.34.6.0.11.2.46"/>
              <templateId root="2.16.840.1.113883.10.20.1.16"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.3.25"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.1.5.3.2"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="8716-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Vital signs"/>
              <title>Vitalparameter</title>
              <text>
                <table>
                  <thead>
                    <tr>
                      <th>Vitalparameterart</th>
                      <th>Wert</th>
                      <th>Einheit</th>
                      <th>Datum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="patmed1">
                      <td ID="patmed2">Körpergröße</td>
                      <td>-</td>
                      <td>cm</td>
                      <td>-</td>
                    </tr>
                    <tr ID="patmed3">
                      <td ID="patmed4">Körpergewicht</td>
                      <td>-</td>
                      <td>kg</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <organizer classCode="CLUSTER" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.23"/>
                  <templateId extension="2015-08-01" root="2.16.840.1.113883.10.20.22.4.26"/>
                  <templateId extension="2015-11-19" root="2.16.840.1.113883.10.20.36.2"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="patmed" root="1.2.40.0.34.99.9999.6.99"/>
                  <code code="46680005" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low nullFlavor="UNK"/>
                    <high nullFlavor="UNK"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.24"/>
                      <templateId root="2.16.840.1.113883.10.20.1.31"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13.2"/>
                      <id assigningAuthorityName="Dr. Test Osteron" extension="patmed1" root="1.2.40.0.34.99.9999.7.99"/>
                      <code code="8302-2" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Körpergröße">
                        <originalText>
                          <reference value="#patmed2"/>
                        </originalText>
                      </code>
                      <text>
                        <reference value="#patmed1"/>
                      </text>
                      <statusCode code="completed"/>
                      <effectiveTime>
                        <low nullFlavor="UNK"/>
                        <high nullFlavor="UNK"/>
                      </effectiveTime>
                      <value nullFlavor="NA" unit="cm" xsi:type="PQ"/>
                    </observation>
                  </component>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.24"/>
                      <templateId root="2.16.840.1.113883.10.20.1.31"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13.2"/>
                      <id assigningAuthorityName="Dr. Test Osteron" extension="patmed3" root="1.2.40.0.34.99.9999.7.99"/>
                      <code code="3141-9" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Körpergewicht">
                        <originalText>
                          <reference value="#patmed4"/>
                        </originalText>
                      </code>
                      <text>
                        <reference value="#patmed3"/>
                      </text>
                      <statusCode code="completed"/>
                      <effectiveTime>
                        <low nullFlavor="UNK"/>
                        <high nullFlavor="UNK"/>
                      </effectiveTime>
                      <value nullFlavor="NA" unit="kg" xsi:type="PQ"/>
                    </observation>
                  </component>
                </organizer>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section>
              <templateId root="1.2.40.0.34.6.0.11.2.31"/>
              <code code="PAT_INFO_MED_MERK" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Merkmale"/>
              <title>Weitere Merkmale</title>
              <text>
                <table>
                  <thead>
                    <tr>
                      <th>Eigenschaft</th>
                      <th>Wert</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Blutgruppe</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Rhesusfaktor</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Besondere Kennzeichen/Hautfarbe</td>
                      <td ID="patmed5">-</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.65"/>
                  <code code="KOERPERLICHEMERKMALE" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Körperliche Merkmale"/>
                  <statusCode code="completed"/>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.66"/>
                      <code code="365637002" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Finding of AB0 blood group"/>
                      <value codeSystem="2.16.840.1.113883.6.96" nullFlavor="NA" xsi:type="CD"/>
                    </observation>
                  </component>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.64"/>
                      <code code="115758001" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Rh blood group phenotype (finding)"/>
                      <value codeSystem="2.16.840.1.113883.6.96" nullFlavor="NA" xsi:type="CD"/>
                    </observation>
                  </component>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.113"/>
                      <code code="BesKennzeichenHautfarbe" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Besondere Kennzeichen / Hautfarbe"/>
                      <value nullFlavor="NA" xsi:type="CD">
                        <originalText>
                          <reference value="#patmed5"/>
                        </originalText>
                      </value>
                    </observation>
                  </component>
                </organizer>
              </entry>
            </section>
          </component>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.40"/>
          <code code="BEFUNDE" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Befunde"/>
          <title>Befunde</title>
          <text>
            <paragraph styleCode="xELGA_h3">Befund vom 13.03.2012</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td ID="befunde1">Befund (13.03.2012)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 13.03.2012</paragraph>
            Befund vom 13.03.2012 als externe Datei beigelegt
            <paragraph styleCode="xELGA_h3">Befund vom 13.03.2012</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2</td>
                  <td ID="befunde2">Befund (13.03.2012)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 13.03.2012</paragraph>
            Befund vom 13.03.2012 als externe Datei beigelegt
            <paragraph styleCode="xELGA_h3">Befund vom 03.09.2017</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3</td>
                  <td ID="befunde3">Befund (03.09.2017)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 03.09.2017</paragraph>
            Befund vom 03.09.2017 als externe Datei beigelegt
            <paragraph styleCode="xELGA_h3">Befund vom 03.09.2017</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>4</td>
                  <td ID="befunde4">Befund (03.09.2017)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 03.09.2017</paragraph>
            Befund vom 03.09.2017 als externe Datei beigelegt
            <paragraph styleCode="xELGA_h3">Befund vom 14.09.2017</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>5</td>
                  <td ID="befunde5">Befund (14.09.2017)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 14.09.2017</paragraph>
            Befund vom 14.09.2017 als externe Datei beigelegt
            <paragraph styleCode="xELGA_h3">Befund vom 01.10.2017</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>6</td>
                  <td ID="befunde6">Befund (01.10.2017)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 01.10.2017</paragraph>
            Befund vom 01.10.2017 als externe Datei beigelegt
            <paragraph styleCode="xELGA_h3">Befund vom 30.11.2017</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>7</td>
                  <td ID="befunde7">Befund (30.11.2017)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 30.11.2017</paragraph>
            Befund vom 30.11.2017 als externe Datei beigelegt
          </text>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde1"/>
                      </text>
                      <effectiveTime>
                        <low value="20120313000000+0100"/>
                      </effectiveTime>
                      <value value="1" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///hs_err_pid5955.log" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde2"/>
                      </text>
                      <effectiveTime>
                        <low value="20120313000000+0100"/>
                      </effectiveTime>
                      <value value="2" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///Messages.java" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde3"/>
                      </text>
                      <effectiveTime>
                        <low value="20170903000000+0100"/>
                      </effectiveTime>
                      <value value="3" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///wonca.ico" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde4"/>
                      </text>
                      <effectiveTime>
                        <low value="20170903000000+0100"/>
                      </effectiveTime>
                      <value value="4" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///wonca16.png" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde5"/>
                      </text>
                      <effectiveTime>
                        <low value="20170914000000+0100"/>
                      </effectiveTime>
                      <value value="5" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///fl-a.stat" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde6"/>
                      </text>
                      <effectiveTime>
                        <low value="20171001000000+0100"/>
                      </effectiveTime>
                      <value value="6" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///flu.stat" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde7"/>
                      </text>
                      <effectiveTime>
                        <low value="20171130000000+0100"/>
                      </effectiveTime>
                      <value value="7" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///bild.jpg" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.96"/>
          <code code="439401001" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Diagnosis"/>
          <title>Diagnose</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Zeitraum oder Zeitpunkt</th>
                  <th>Diagnosetext</th>
                  <th>Code [Codesystem]</th>
                  <th>Diagnoseart</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>16.08.2010</td>
                  <td ID="diag1">Ekzem</td>
                  <td>N3 [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.11.2010</td>
                  <td ID="diag2">Fremdkörper an sonstigen und mehreren Lokalisationen des Urogenitaltraktes</td>
                  <td>T19.8 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.11.2010</td>
                  <td ID="diag3">Bösartige Neubildung: Retroperitoneum</td>
                  <td>C48.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.11.2010</td>
                  <td ID="diag4">Unfall Kopf / Wirbelsäule</td>
                  <td>R1 [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>03.12.2010</td>
                  <td ID="diag5">Anderes</td>
                  <td>R9 [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>09.12.2010</td>
                  <td ID="diag6">Oberflächliche Verletzung sonstiger Teile des Kopfes</td>
                  <td>S00.8 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>09.12.2010</td>
                  <td ID="diag7">Unfall Kopf / Wirbelsäule</td>
                  <td>R1 [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>09.12.2010</td>
                  <td ID="diag8">Oberflächliche Verletzung sonstiger Teile des Kopfes</td>
                  <td>S00.8 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>24.06.2011</td>
                  <td ID="diag9">Penetrierende Wunde des Augapfels mit Fremdkörper</td>
                  <td>S05.5 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>28.06.2011</td>
                  <td ID="diag10">Ausgebranntsein</td>
                  <td>Z73.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>15.10.2011</td>
                  <td ID="diag11">Rezessive Depersonalisierung</td>
                  <td>dep [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>15.10.2011</td>
                  <td ID="diag12">Tungiasis [Sandflohbefall]</td>
                  <td>B88.1 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>20.02.2013</td>
                  <td ID="diag13">Kopfverletzung</td>
                  <td>-</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>20.02.2013</td>
                  <td ID="diag14">Grippe, Viren nicht nachgewiesen</td>
                  <td>J11 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.01.2011</td>
                  <td ID="diag15">Palpitationen</td>
                  <td>R00.2 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>21.05.2011</td>
                  <td ID="diag16">Sonstige Mykosen infolge HIV-Krankheit</td>
                  <td>B20.5 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>21.05.2011</td>
                  <td ID="diag17">Kontakt mit giftigen Schlangen oder Echsen</td>
                  <td>X20 [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>21.05.2011</td>
                  <td ID="diag18">Kandidose infolge HIV-Krankheit</td>
                  <td>B20.4 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>21.05.2011</td>
                  <td ID="diag19">Spezielle Verfahren zur Untersuchung auf Neubildung der Atmungsorgane</td>
                  <td>Z12.2 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>02.09.2011</td>
                  <td ID="diag20">Grippaler Infekt</td>
                  <td>gi [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.09.2011</td>
                  <td ID="diag21">null</td>
                  <td>null [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.09.2011</td>
                  <td ID="diag22">null</td>
                  <td>null [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.09.2011</td>
                  <td ID="diag23">null</td>
                  <td>null [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>01.10.2011</td>
                  <td ID="diag24">Grippe mit sonstigen Manifestationen, Viren nicht nachgewiesen</td>
                  <td>J11.8 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>01.10.2011</td>
                  <td ID="diag25">Paratyphus C</td>
                  <td>A01.3 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>01.10.2011</td>
                  <td ID="diag26">Aufmerksamkeitsdefizitsyndrom</td>
                  <td>hyp [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>01.10.2011</td>
                  <td ID="diag27">Hypokorrektheit</td>
                  <td>hyko [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>06.10.2011</td>
                  <td ID="diag28">Aufmerksamkeitsdefizitsyndrom</td>
                  <td>hyp [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>06.10.2011</td>
                  <td ID="diag29">Hyperkorrektheit</td>
                  <td>hyk [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>06.10.2011</td>
                  <td ID="diag30">Progressive Verblödung</td>
                  <td>pm [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>06.10.2011</td>
                  <td ID="diag31">Rezessive Depersonalisierung</td>
                  <td>dep [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>09.10.2011</td>
                  <td ID="diag32">Grippaler Infekt</td>
                  <td>gi [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.10.2011</td>
                  <td ID="diag33">Aufmerksamkeitsdefizitsyndrom</td>
                  <td>hyp [Eigendiagnose]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>22.12.2011</td>
                  <td ID="diag34">Oberflächliche Verletzungen mit Beteiligung von Kopf und Hals</td>
                  <td>T00.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>24.01.2011</td>
                  <td ID="diag35">Oberflächliche Verletzung sonstiger Teile des Kopfes</td>
                  <td>S00.8 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>28.01.2011</td>
                  <td ID="diag36">Cyclitis posterior</td>
                  <td>H30.2 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>24.02.2011</td>
                  <td ID="diag37">Benigne und akzidentelle Herzgeräusche</td>
                  <td>R01.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>25.02.2011</td>
                  <td ID="diag38">Bösartige Neubildung: Lippe, nicht näher bezeichnet</td>
                  <td>C00.9 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>10.03.2011</td>
                  <td ID="diag39">Entwicklung körperlicher Symptome aus psychischen Gründen</td>
                  <td>F68.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>10.03.2011</td>
                  <td ID="diag40">Emphysem</td>
                  <td>J43 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>22.06.2011</td>
                  <td ID="diag41">Histiozyten- und Mastzelltumor unsicheren oder unbekannten Verhaltens</td>
                  <td>D47.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>22.06.2011</td>
                  <td ID="diag42">Nicht primär insulinabhängiger Diabetes mellitus [Typ-2-Diabetes]</td>
                  <td>E11 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>07.07.2011</td>
                  <td ID="diag43">Lungenembolie ohne Angabe eines akuten Cor pulmonale</td>
                  <td>I26.9 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>22.01.2012</td>
                  <td ID="diag44">Akute Blutungsanämie</td>
                  <td>D62 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>27.01.2012</td>
                  <td ID="diag45">Blut- und Immunanomalien infolge HIV-Krankheit, anderenorts nicht klassifiziert</td>
                  <td>B23.2 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>27.04.2012</td>
                  <td ID="diag46">Ausgebranntsein</td>
                  <td>Z73.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>09.07.2012</td>
                  <td ID="diag47">Otitis externa bei anderenorts klassifizierten Mykosen</td>
                  <td>H62.2* [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>24.08.2012</td>
                  <td ID="diag48">Ausgebranntsein</td>
                  <td>Z73.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.03.2014</td>
                  <td ID="diag49">Fissur und Fistel in der Anal- und Rektalregion</td>
                  <td>K60 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>29.12.2015</td>
                  <td ID="diag50">Arbeitsscheue</td>
                  <td>-</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>20.06.2016</td>
                  <td ID="diag51">Einfache Aktivitäts- und Aufmerksamkeitsstörung</td>
                  <td>F90.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>06.10.2017</td>
                  <td ID="diag52">Einfache Aktivitäts- und Aufmerksamkeitsstörung</td>
                  <td>F90.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>14.03.2011</td>
                  <td ID="diag53">Fußgänger bei Zusammenstoß mit Fahrrad verletzt</td>
                  <td>V01 [Freitext]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>27.04.2011</td>
                  <td ID="diag54">Bradykardie, nicht näher bezeichnet</td>
                  <td>R00.1 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>27.04.2011</td>
                  <td ID="diag55">Benigne und akzidentelle Herzgeräusche</td>
                  <td>R01.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>25.05.2011</td>
                  <td ID="diag56">Submuköses Leiomyom des Uterus</td>
                  <td>D25.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>06.06.2011</td>
                  <td ID="diag57">Plasmozytom und bösartige Plasmazellen-Neubildungen</td>
                  <td>C90 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>06.06.2011</td>
                  <td ID="diag58">Grippe, Viren nicht nachgewiesen</td>
                  <td>J11 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>06.06.2011</td>
                  <td ID="diag59">Typhus abdominalis und Paratyphus</td>
                  <td>A01 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>17.06.2011</td>
                  <td ID="diag60">Tod ohne Anwesenheit anderer Personen</td>
                  <td>R98 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>01.09.2011</td>
                  <td ID="diag61">Angeborenes Jodmangelsyndrom, neurologischer Typ</td>
                  <td>E00.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>01.09.2011</td>
                  <td ID="diag62">Glaukomverdacht</td>
                  <td>H40.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>08.06.2012</td>
                  <td ID="diag63">Ausgebranntsein</td>
                  <td>Z73.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>28.06.2012</td>
                  <td ID="diag64">Gastritis und Duodenitis</td>
                  <td>K29 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>29.06.2012</td>
                  <td ID="diag65">Zervikale Bandscheibenschäden</td>
                  <td>M50 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>22.06.2013</td>
                  <td ID="diag66">Anämie, nicht näher bezeichnet - Vd</td>
                  <td>D64.9 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>10.07.2013</td>
                  <td ID="diag67">psychosomatisch</td>
                  <td>-</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>01.11.2013</td>
                  <td ID="diag68">Bipolare affektive Störung, gegenwärtig hypomanische Episode</td>
                  <td>F31.0 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.02.2014</td>
                  <td ID="diag69">Otalgie und Ohrenfluss</td>
                  <td>H92 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>24.02.2014</td>
                  <td ID="diag70">Arbeitsscheue</td>
                  <td>-</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>18.03.2015</td>
                  <td ID="diag71">Bösartige Neubildung des Recessus piriformis - - Vd</td>
                  <td>C12 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>02.06.2016</td>
                  <td ID="diag72">Fissur und Fistel in der Anal- und Rektalregion</td>
                  <td>K60 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>12.10.2016</td>
                  <td ID="diag73">Fissur und Fistel in der Anal- und Rektalregion</td>
                  <td>K60 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>20.05.2018</td>
                  <td ID="diag74">Erschöpfung durch übermäßige Anstrengung</td>
                  <td>T73.3 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>07.05.2021</td>
                  <td ID="diag75">Arbeitsscheue</td>
                  <td>-</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>10.09.2021</td>
                  <td ID="diag76">Verkehrsunfall als Arbeitsunfall</td>
                  <td>912 [ICD10]</td>
                  <td>Hauptdiagnose</td>
                </tr>
                <tr>
                  <td>10.09.2021</td>
                  <td ID="diag77">Arbeitsscheue</td>
                  <td>-</td>
                  <td>Hauptdiagnose</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20100816"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag1"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20100816"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag1"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20101118"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag2"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20101118"/>
                  </effectiveTime>
                  <value code="T19.8" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag2"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20101118"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag3"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20101118"/>
                  </effectiveTime>
                  <value code="C48.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag3"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20101118"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag4"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20101118"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag4"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20101203"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag5"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20101203"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag5"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20101209"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag6"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20101209"/>
                  </effectiveTime>
                  <value code="S00.8" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag6"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20101209"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag7"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20101209"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag7"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20101209"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag8"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20101209"/>
                  </effectiveTime>
                  <value code="S00.8" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag8"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110624"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag9"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110624"/>
                  </effectiveTime>
                  <value code="S05.5" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag9"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110628"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag10"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110628"/>
                  </effectiveTime>
                  <value code="Z73.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag10"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111015"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag11"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111015"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag11"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111015"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag12"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111015"/>
                  </effectiveTime>
                  <value code="B88.1" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag12"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20130220"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag13"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20130220"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag13"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20130220"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag14"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20130220"/>
                  </effectiveTime>
                  <value code="J11" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag14"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110118"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag15"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110118"/>
                  </effectiveTime>
                  <value code="R00.2" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag15"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110521"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag16"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110521"/>
                  </effectiveTime>
                  <value code="B20.5" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag16"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110521"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag17"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110521"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag17"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110521"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag18"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110521"/>
                  </effectiveTime>
                  <value code="B20.4" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag18"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110521"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag19"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110521"/>
                  </effectiveTime>
                  <value code="Z12.2" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag19"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110902"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag20"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110902"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag20"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110918"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag21"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110918"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag21"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110918"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag22"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110918"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag22"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110918"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag23"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110918"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag23"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111001"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag24"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111001"/>
                  </effectiveTime>
                  <value code="J11.8" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag24"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111001"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag25"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111001"/>
                  </effectiveTime>
                  <value code="A01.3" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag25"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111001"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag26"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111001"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag26"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111001"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag27"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111001"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag27"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111006"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag28"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111006"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag28"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111006"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag29"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111006"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag29"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111006"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag30"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111006"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag30"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111006"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag31"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111006"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag31"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111009"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag32"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111009"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag32"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111018"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag33"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111018"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag33"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20111222"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag34"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20111222"/>
                  </effectiveTime>
                  <value code="T00.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag34"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110124"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag35"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110124"/>
                  </effectiveTime>
                  <value code="S00.8" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag35"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110128"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag36"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110128"/>
                  </effectiveTime>
                  <value code="H30.2" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag36"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110224"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag37"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110224"/>
                  </effectiveTime>
                  <value code="R01.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag37"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110225"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag38"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110225"/>
                  </effectiveTime>
                  <value code="C00.9" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag38"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110310"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag39"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110310"/>
                  </effectiveTime>
                  <value code="F68.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag39"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110310"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag40"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110310"/>
                  </effectiveTime>
                  <value code="J43" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag40"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110622"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag41"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110622"/>
                  </effectiveTime>
                  <value code="D47.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag41"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110622"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag42"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110622"/>
                  </effectiveTime>
                  <value code="E11" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag42"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110707"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag43"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110707"/>
                  </effectiveTime>
                  <value code="I26.9" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag43"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20120122"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag44"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20120122"/>
                  </effectiveTime>
                  <value code="D62" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag44"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20120127"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag45"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20120127"/>
                  </effectiveTime>
                  <value code="B23.2" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag45"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20120427"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag46"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20120427"/>
                  </effectiveTime>
                  <value code="Z73.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag46"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20120709"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag47"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20120709"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag47"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20120824"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag48"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20120824"/>
                  </effectiveTime>
                  <value code="Z73.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag48"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20140318"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag49"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20140318"/>
                  </effectiveTime>
                  <value code="K60" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag49"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20151229"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag50"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20151229"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag50"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20160620"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag51"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20160620"/>
                  </effectiveTime>
                  <value code="F90.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag51"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20171006"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag52"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20171006"/>
                  </effectiveTime>
                  <value code="F90.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag52"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110314"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag53"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110314"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag53"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110427"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag54"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110427"/>
                  </effectiveTime>
                  <value code="R00.1" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag54"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110427"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag55"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110427"/>
                  </effectiveTime>
                  <value code="R01.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag55"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110525"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag56"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110525"/>
                  </effectiveTime>
                  <value code="D25.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag56"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110606"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag57"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110606"/>
                  </effectiveTime>
                  <value code="C90" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag57"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110606"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag58"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110606"/>
                  </effectiveTime>
                  <value code="J11" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag58"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110606"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag59"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110606"/>
                  </effectiveTime>
                  <value code="A01" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag59"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110617"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag60"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110617"/>
                  </effectiveTime>
                  <value code="R98" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag60"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110901"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag61"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110901"/>
                  </effectiveTime>
                  <value code="E00.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag61"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20110901"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag62"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20110901"/>
                  </effectiveTime>
                  <value code="H40.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag62"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20120608"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag63"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20120608"/>
                  </effectiveTime>
                  <value code="Z73.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag63"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20120628"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag64"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20120628"/>
                  </effectiveTime>
                  <value code="K29" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag64"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20120629"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag65"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20120629"/>
                  </effectiveTime>
                  <value code="M50" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag65"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20130622"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag66"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20130622"/>
                  </effectiveTime>
                  <value code="D64.9" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag66"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20130710"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag67"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20130710"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag67"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20131101"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag68"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20131101"/>
                  </effectiveTime>
                  <value code="F31.0" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag68"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20140218"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag69"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20140218"/>
                  </effectiveTime>
                  <value code="H92" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag69"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20140224"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag70"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20140224"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag70"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20150318"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag71"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20150318"/>
                  </effectiveTime>
                  <value code="C12" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag71"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20160602"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag72"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20160602"/>
                  </effectiveTime>
                  <value code="K60" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag72"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20161012"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag73"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20161012"/>
                  </effectiveTime>
                  <value code="K60" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag73"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20180520"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag74"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20180520"/>
                  </effectiveTime>
                  <value code="T73.3" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag74"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20210507"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag75"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20210507"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag75"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20210910"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag76"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20210910"/>
                  </effectiveTime>
                  <value code="912" codeSystem="1.2.40.0.34.5.184" xsi:type="CD">
                    <originalText>
                      <reference value="#diag76"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT">
              <templateId root="1.2.40.0.34.6.0.11.3.7"/>
              <templateId root="2.16.840.1.113883.10.20.1.27"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.1"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5.2"/>
              <code nullFlavor="NA"/>
              <statusCode code="active"/>
              <effectiveTime>
                <low value="20210910"/>
              </effectiveTime>
              <entryRelationship contextConductionInd="true" inversionInd="false" typeCode="SUBJ">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.6"/>
                  <templateId root="2.16.840.1.113883.10.20.1.28"/>
                  <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.5"/>
                  <code code="64572001" codeSystem="2.16.840.1.113883.6.96" displayName="Zustand">
                    <originalText>
                      <reference value="#diag77"/>
                    </originalText>
                  </code>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20210910"/>
                  </effectiveTime>
                  <value xsi:type="CD">
                    <originalText>
                      <reference value="#diag77"/>
                    </originalText>
                    <qualifier>
                      <name code="106229004" codeSystem="2.16.840.1.113883.6.96"/>
                      <value code="8319008" codeSystem="2.16.840.1.113883.6.96" displayName="Hauptdiagnose"/>
                    </qualifier>
                  </value>
                </observation>
              </entryRelationship>
            </act>
          </entry>
        </section>
      </component>
      <component typeCode="COMP"/>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.34"/>
          <code code="KARTEI_EINTRAGUNGEN" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Karteineintragungen"/>
          <title>Karteineintragungen</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Zeile</th>
                  <th>Datum</th>
                  <th>Kürzel</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>13.08.2010</td>
                  <td>RE</td>
                  <td ID="kartei1">2010/7 1.075,80 (Offen und gedruckt) [2]</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>18.11.2010</td>
                  <td>RE</td>
                  <td ID="kartei2">2010/5 30,00 (Bezahlt) [8]</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>18.11.2010</td>
                  <td>RE</td>
                  <td ID="kartei3">2010/6 3,75 (Bezahlt) [6]</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>19.11.2010</td>
                  <td>RE</td>
                  <td ID="kartei4">2010/4 5,00 (Bezahlt) [9]</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>03.12.2010</td>
                  <td>RE</td>
                  <td ID="kartei5">2010/3 189,85 (Bezahlt) [10]</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>09.12.2010</td>
                  <td>RE</td>
                  <td ID="kartei6">2010/1 60,00 (Bezahlt) [12]</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>09.12.2010</td>
                  <td>RE</td>
                  <td ID="kartei7">2010/2 60,00 (Bezahlt) [11]</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>24.01.2011</td>
                  <td>RE</td>
                  <td ID="kartei8">2011/6 102,20 (Bezahlt) [14]</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>24.01.2011</td>
                  <td>RE</td>
                  <td ID="kartei9">2011/9 32,73 (Bezahlt) [13]</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>24.02.2011</td>
                  <td>RE</td>
                  <td ID="kartei10">2011/5 138,50 (Bezahlt) [15]</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>24.02.2011</td>
                  <td>RE</td>
                  <td ID="kartei11">2011/4 18,24 (Bezahlt) [16]</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>07.07.2011</td>
                  <td>RE</td>
                  <td ID="kartei12">2011/3 36,00 (Bezahlt) [17]</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>09.10.2011</td>
                  <td>RE</td>
                  <td ID="kartei13">2011/8 84,00 (Bezahlt) [18]</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>18.10.2011</td>
                  <td>RE</td>
                  <td ID="kartei14">2011/7 164,40 (Bezahlt) [19]</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>13.12.2011</td>
                  <td>RE</td>
                  <td ID="kartei15">2011/1 28,95 (Bezahlt) [20]</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>22.12.2011</td>
                  <td>TX</td>
                  <td ID="kartei16">Rückdatierter Text vom 5. Jun. 2012</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>27.04.2012</td>
                  <td>AU</td>
                  <td ID="kartei17">Seit 27.04.2012: Krankheit</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>27.04.2012</td>
                  <td>Ü</td>
                  <td ID="kartei18">Wurst (UW): Psychotherapie</td>
                </tr>
                <tr>
                  <td>19</td>
                  <td>05.06.2012 11:40</td>
                  <td>TX</td>
                  <td ID="kartei19">bla bla laber flaber</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>05.06.2012 11:40</td>
                  <td>TX</td>
                  <td ID="kartei20">Hier kann ich beliebig viel

Freitext erfassen</td>
                </tr>
                <tr>
                  <td>21</td>
                  <td>05.06.2012 11:41</td>
                  <td>TX</td>
                  <td ID="kartei21">hmm, solange die Kartei nachlädt, verschwindet periodisch die Eingabezeile. Ich kann das noch nicht reproduzieren, muss das aber bald</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>29.06.2012 11:27</td>
                  <td>TX</td>
                  <td ID="kartei22">laber laber</td>
                </tr>
                <tr>
                  <td>23</td>
                  <td>29.06.2012 11:38</td>
                  <td>TX</td>
                  <td ID="kartei23">la la</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>29.06.2012 14:36</td>
                  <td>TX</td>
                  <td ID="kartei24">mehr text</td>
                </tr>
                <tr>
                  <td>25</td>
                  <td>29.06.2012 14:36</td>
                  <td>TX</td>
                  <td ID="kartei25">noch mehr</td>
                </tr>
                <tr>
                  <td>26</td>
                  <td>29.06.2012 15:29</td>
                  <td>TX</td>
                  <td ID="kartei26">flabber</td>
                </tr>
                <tr>
                  <td>27</td>
                  <td>09.07.2012</td>
                  <td>RE</td>
                  <td ID="kartei27">2012/5 2.830,80 (Bezahlt) [30]</td>
                </tr>
                <tr>
                  <td>28</td>
                  <td>24.08.2012</td>
                  <td>RE</td>
                  <td ID="kartei28">2012/8 72,00 (Bezahlt) [33]</td>
                </tr>
                <tr>
                  <td>29</td>
                  <td>26.08.2012 18:33</td>
                  <td>BTX</td>
                  <td ID="kartei29">unbrauchbar aber schön</td>
                </tr>
                <tr>
                  <td>30</td>
                  <td>10.09.2012</td>
                  <td>TR</td>
                  <td ID="kartei30">ÖPNV zu Freund</td>
                </tr>
                <tr>
                  <td>31</td>
                  <td>11.09.2012</td>
                  <td>BR</td>
                  <td ID="kartei31">Schranz: Sonderbefund absolute Absenteritis</td>
                </tr>
                <tr>
                  <td>32</td>
                  <td>25.11.2012</td>
                  <td>AU</td>
                  <td ID="kartei32">25.11.2012 - (03.11.2012): Krankheit</td>
                </tr>
                <tr>
                  <td>33</td>
                  <td>20.02.2013</td>
                  <td>Ü</td>
                  <td ID="kartei33">Wels Krankenhaus (EW): Plastische Chirurgie</td>
                </tr>
                <tr>
                  <td>34</td>
                  <td>20.02.2013</td>
                  <td>AU</td>
                  <td ID="kartei34">15.02.2013 - (20.03.2013): Unfall</td>
                </tr>
                <tr>
                  <td>35</td>
                  <td>20.02.2013</td>
                  <td>TR</td>
                  <td ID="kartei35">Krankentransport zu Wels Krankenhaus</td>
                </tr>
                <tr>
                  <td>36</td>
                  <td>06.06.2013</td>
                  <td>BR</td>
                  <td ID="kartei36">Freund: weiß nicht</td>
                </tr>
                <tr>
                  <td>37</td>
                  <td>10.07.2013</td>
                  <td>Ü</td>
                  <td ID="kartei37">Landesnervenklinik Wagner-Jauregg GESPAG PDF (UW): Behandlung auf alte Art</td>
                </tr>
                <tr>
                  <td>38</td>
                  <td>22.07.2013 18:00</td>
                  <td>TX</td>
                  <td ID="kartei38">Musste noch ein Medikament nachholen</td>
                </tr>
                <tr>
                  <td>39</td>
                  <td>24.02.2014</td>
                  <td>RE</td>
                  <td ID="kartei39">2014/10 47,01 (Bezahlt) [122]</td>
                </tr>
                <tr>
                  <td>40</td>
                  <td>24.02.2014</td>
                  <td>RE</td>
                  <td ID="kartei40">2014/11 17,71 (Bezahlt) [123]</td>
                </tr>
                <tr>
                  <td>41</td>
                  <td>24.02.2014</td>
                  <td>RE</td>
                  <td ID="kartei41">2014/13 59,27 (Bezahlt) [125]</td>
                </tr>
                <tr>
                  <td>42</td>
                  <td>18.03.2014</td>
                  <td>RE</td>
                  <td ID="kartei42">2014/21 40,00 (Bezahlt) [145]</td>
                </tr>
                <tr>
                  <td>43</td>
                  <td>18.03.2015 16:09</td>
                  <td>TX</td>
                  <td ID="kartei43">Typisch Grieche halt</td>
                </tr>
                <tr>
                  <td>44</td>
                  <td>18.03.2015</td>
                  <td>Ü</td>
                  <td ID="kartei44">Wels Krankenhaus (UW): Röntgen, recessus prii</td>
                </tr>
                <tr>
                  <td>45</td>
                  <td>18.03.2015</td>
                  <td>TR</td>
                  <td ID="kartei45">Transport zu Wels Krankenhaus</td>
                </tr>
                <tr>
                  <td>46</td>
                  <td>18.03.2015</td>
                  <td>RE</td>
                  <td ID="kartei46">2015/5 0,00 (Storniert) [193]</td>
                </tr>
                <tr>
                  <td>47</td>
                  <td>18.03.2015</td>
                  <td>RE</td>
                  <td ID="kartei47">2015/6 62,68 (Bezahlt) [194]</td>
                </tr>
                <tr>
                  <td>48</td>
                  <td>29.12.2015</td>
                  <td>RE</td>
                  <td ID="kartei48">2015/13 1.008,00 (Rn. offen) [208]</td>
                </tr>
                <tr>
                  <td>49</td>
                  <td>29.12.2015</td>
                  <td>RE</td>
                  <td ID="kartei49">2015/12 3.512,69 (Offen und gedruckt) [207]</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td>29.12.2015</td>
                  <td>RE</td>
                  <td ID="kartei50">2015/14 1.414,80 (Rn. offen) [209]</td>
                </tr>
                <tr>
                  <td>51</td>
                  <td>29.12.2015</td>
                  <td>RE</td>
                  <td ID="kartei51">2015/11 199,73 (Bezahlt) [206]</td>
                </tr>
                <tr>
                  <td>52</td>
                  <td>29.02.2016</td>
                  <td>TF</td>
                  <td ID="kartei52">Formular zum Testen</td>
                </tr>
                <tr>
                  <td>53</td>
                  <td>20.06.2016 15:34</td>
                  <td>TX</td>
                  <td ID="kartei53">Eigenleistung verrechnen, die keiner Kassa angehört</td>
                </tr>
                <tr>
                  <td>54</td>
                  <td>12.10.2016</td>
                  <td>RE</td>
                  <td ID="kartei54">2016/12 32,84 (Bezahlt) [222]</td>
                </tr>
                <tr>
                  <td>55</td>
                  <td>06.10.2017 08:16</td>
                  <td>TX</td>
                  <td ID="kartei55">Scheine duplizieren getestet</td>
                </tr>
                <tr>
                  <td>56</td>
                  <td>06.10.2017</td>
                  <td>BR</td>
                  <td ID="kartei56">Schranz: Sonderbefund absolute Absenteritis</td>
                </tr>
                <tr>
                  <td>57</td>
                  <td>06.10.2017</td>
                  <td>Ü</td>
                  <td ID="kartei57">Wurst (UW): Psychotherapie nach Alter Art</td>
                </tr>
                <tr>
                  <td>58</td>
                  <td>06.10.2017</td>
                  <td>AU</td>
                  <td ID="kartei58">05.10.2017 - (20.11.2017): Krankheit</td>
                </tr>
                <tr>
                  <td>59</td>
                  <td>22.06.2018</td>
                  <td>TF</td>
                  <td ID="kartei59">Formular zum Testen</td>
                </tr>
                <tr>
                  <td>60</td>
                  <td>02.07.2018</td>
                  <td>Ü</td>
                  <td ID="kartei60">Wurst (UW): Psychotherapie nach Alter Art</td>
                </tr>
                <tr>
                  <td>61</td>
                  <td>10.09.2021</td>
                  <td>TF</td>
                  <td ID="kartei61">Formular zum Testen</td>
                </tr>
                <tr>
                  <td>62</td>
                  <td>10.09.2021</td>
                  <td>CT</td>
                  <td ID="kartei62">Danzer (CT)</td>
                </tr>
                <tr>
                  <td>63</td>
                  <td>10.09.2021</td>
                  <td>RE</td>
                  <td ID="kartei63">2021/17 36,00 (Storniert) [259]</td>
                </tr>
                <tr>
                  <td>64</td>
                  <td>10.09.2021</td>
                  <td>RE</td>
                  <td ID="kartei64">2021/18 36,00 (Offen und gedruckt) [260]</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.137"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
              <statusCode code="completed"/>
              <effectiveTime>
                <low value="20220228214615+0100"/>
              </effectiveTime>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile1" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei1"/>
                  </text>
                  <effectiveTime>
                    <low value="20100813000000+0100"/>
                  </effectiveTime>
                  <value value="1" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile2" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei2"/>
                  </text>
                  <effectiveTime>
                    <low value="20101118000000+0100"/>
                  </effectiveTime>
                  <value value="2" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile3" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei3"/>
                  </text>
                  <effectiveTime>
                    <low value="20101118000000+0100"/>
                  </effectiveTime>
                  <value value="3" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile4" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei4"/>
                  </text>
                  <effectiveTime>
                    <low value="20101119000000+0100"/>
                  </effectiveTime>
                  <value value="4" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile5" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei5"/>
                  </text>
                  <effectiveTime>
                    <low value="20101203000000+0100"/>
                  </effectiveTime>
                  <value value="5" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile6" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei6"/>
                  </text>
                  <effectiveTime>
                    <low value="20101209000000+0100"/>
                  </effectiveTime>
                  <value value="6" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile7" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei7"/>
                  </text>
                  <effectiveTime>
                    <low value="20101209000000+0100"/>
                  </effectiveTime>
                  <value value="7" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile8" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei8"/>
                  </text>
                  <effectiveTime>
                    <low value="20110124000000+0100"/>
                  </effectiveTime>
                  <value value="8" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile9" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei9"/>
                  </text>
                  <effectiveTime>
                    <low value="20110124000000+0100"/>
                  </effectiveTime>
                  <value value="9" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile10" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei10"/>
                  </text>
                  <effectiveTime>
                    <low value="20110224000000+0100"/>
                  </effectiveTime>
                  <value value="10" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile11" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei11"/>
                  </text>
                  <effectiveTime>
                    <low value="20110224000000+0100"/>
                  </effectiveTime>
                  <value value="11" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile12" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei12"/>
                  </text>
                  <effectiveTime>
                    <low value="20110707000000+0100"/>
                  </effectiveTime>
                  <value value="12" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile13" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei13"/>
                  </text>
                  <effectiveTime>
                    <low value="20111009000000+0100"/>
                  </effectiveTime>
                  <value value="13" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile14" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei14"/>
                  </text>
                  <effectiveTime>
                    <low value="20111018000000+0100"/>
                  </effectiveTime>
                  <value value="14" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile15" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei15"/>
                  </text>
                  <effectiveTime>
                    <low value="20111213000000+0100"/>
                  </effectiveTime>
                  <value value="15" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile16" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei16"/>
                  </text>
                  <effectiveTime>
                    <low value="20111222000000+0100"/>
                  </effectiveTime>
                  <value value="16" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile17" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei17"/>
                  </text>
                  <effectiveTime>
                    <low value="20120427000000+0100"/>
                  </effectiveTime>
                  <value value="17" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile18" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei18"/>
                  </text>
                  <effectiveTime>
                    <low value="20120427000000+0100"/>
                  </effectiveTime>
                  <value value="18" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile19" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei19"/>
                  </text>
                  <effectiveTime>
                    <low value="20120605114000+0100"/>
                  </effectiveTime>
                  <value value="19" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile20" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei20"/>
                  </text>
                  <effectiveTime>
                    <low value="20120605114000+0100"/>
                  </effectiveTime>
                  <value value="20" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile21" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei21"/>
                  </text>
                  <effectiveTime>
                    <low value="20120605114100+0100"/>
                  </effectiveTime>
                  <value value="21" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile22" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei22"/>
                  </text>
                  <effectiveTime>
                    <low value="20120629112700+0100"/>
                  </effectiveTime>
                  <value value="22" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile23" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei23"/>
                  </text>
                  <effectiveTime>
                    <low value="20120629113800+0100"/>
                  </effectiveTime>
                  <value value="23" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile24" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei24"/>
                  </text>
                  <effectiveTime>
                    <low value="20120629143600+0100"/>
                  </effectiveTime>
                  <value value="24" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile25" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei25"/>
                  </text>
                  <effectiveTime>
                    <low value="20120629143600+0100"/>
                  </effectiveTime>
                  <value value="25" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile26" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei26"/>
                  </text>
                  <effectiveTime>
                    <low value="20120629152900+0100"/>
                  </effectiveTime>
                  <value value="26" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile27" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei27"/>
                  </text>
                  <effectiveTime>
                    <low value="20120709000000+0100"/>
                  </effectiveTime>
                  <value value="27" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile28" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei28"/>
                  </text>
                  <effectiveTime>
                    <low value="20120824000000+0100"/>
                  </effectiveTime>
                  <value value="28" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile29" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei29"/>
                  </text>
                  <effectiveTime>
                    <low value="20120826183300+0100"/>
                  </effectiveTime>
                  <value value="29" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile30" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei30"/>
                  </text>
                  <effectiveTime>
                    <low value="20120910000000+0100"/>
                  </effectiveTime>
                  <value value="30" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile31" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei31"/>
                  </text>
                  <effectiveTime>
                    <low value="20120911000000+0100"/>
                  </effectiveTime>
                  <value value="31" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile32" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei32"/>
                  </text>
                  <effectiveTime>
                    <low value="20121125000000+0100"/>
                  </effectiveTime>
                  <value value="32" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile33" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei33"/>
                  </text>
                  <effectiveTime>
                    <low value="20130220000000+0100"/>
                  </effectiveTime>
                  <value value="33" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile34" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei34"/>
                  </text>
                  <effectiveTime>
                    <low value="20130220000000+0100"/>
                  </effectiveTime>
                  <value value="34" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile35" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei35"/>
                  </text>
                  <effectiveTime>
                    <low value="20130220000000+0100"/>
                  </effectiveTime>
                  <value value="35" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile36" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei36"/>
                  </text>
                  <effectiveTime>
                    <low value="20130606000000+0100"/>
                  </effectiveTime>
                  <value value="36" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile37" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei37"/>
                  </text>
                  <effectiveTime>
                    <low value="20130710000000+0100"/>
                  </effectiveTime>
                  <value value="37" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile38" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei38"/>
                  </text>
                  <effectiveTime>
                    <low value="20130722180000+0100"/>
                  </effectiveTime>
                  <value value="38" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile39" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei39"/>
                  </text>
                  <effectiveTime>
                    <low value="20140224000000+0100"/>
                  </effectiveTime>
                  <value value="39" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile40" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei40"/>
                  </text>
                  <effectiveTime>
                    <low value="20140224000000+0100"/>
                  </effectiveTime>
                  <value value="40" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile41" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei41"/>
                  </text>
                  <effectiveTime>
                    <low value="20140224000000+0100"/>
                  </effectiveTime>
                  <value value="41" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile42" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei42"/>
                  </text>
                  <effectiveTime>
                    <low value="20140318000000+0100"/>
                  </effectiveTime>
                  <value value="42" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile43" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei43"/>
                  </text>
                  <effectiveTime>
                    <low value="20150318160900+0100"/>
                  </effectiveTime>
                  <value value="43" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile44" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei44"/>
                  </text>
                  <effectiveTime>
                    <low value="20150318000000+0100"/>
                  </effectiveTime>
                  <value value="44" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile45" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei45"/>
                  </text>
                  <effectiveTime>
                    <low value="20150318000000+0100"/>
                  </effectiveTime>
                  <value value="45" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile46" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei46"/>
                  </text>
                  <effectiveTime>
                    <low value="20150318000000+0100"/>
                  </effectiveTime>
                  <value value="46" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile47" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei47"/>
                  </text>
                  <effectiveTime>
                    <low value="20150318000000+0100"/>
                  </effectiveTime>
                  <value value="47" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile48" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei48"/>
                  </text>
                  <effectiveTime>
                    <low value="20151229000000+0100"/>
                  </effectiveTime>
                  <value value="48" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile49" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei49"/>
                  </text>
                  <effectiveTime>
                    <low value="20151229000000+0100"/>
                  </effectiveTime>
                  <value value="49" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile50" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei50"/>
                  </text>
                  <effectiveTime>
                    <low value="20151229000000+0100"/>
                  </effectiveTime>
                  <value value="50" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile51" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei51"/>
                  </text>
                  <effectiveTime>
                    <low value="20151229000000+0100"/>
                  </effectiveTime>
                  <value value="51" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile52" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei52"/>
                  </text>
                  <effectiveTime>
                    <low value="20160229000000+0100"/>
                  </effectiveTime>
                  <value value="52" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile53" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei53"/>
                  </text>
                  <effectiveTime>
                    <low value="20160620153400+0100"/>
                  </effectiveTime>
                  <value value="53" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile54" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei54"/>
                  </text>
                  <effectiveTime>
                    <low value="20161012000000+0100"/>
                  </effectiveTime>
                  <value value="54" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile55" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei55"/>
                  </text>
                  <effectiveTime>
                    <low value="20171006081600+0100"/>
                  </effectiveTime>
                  <value value="55" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile56" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei56"/>
                  </text>
                  <effectiveTime>
                    <low value="20171006000000+0100"/>
                  </effectiveTime>
                  <value value="56" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile57" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei57"/>
                  </text>
                  <effectiveTime>
                    <low value="20171006000000+0100"/>
                  </effectiveTime>
                  <value value="57" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile58" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei58"/>
                  </text>
                  <effectiveTime>
                    <low value="20171006000000+0100"/>
                  </effectiveTime>
                  <value value="58" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile59" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei59"/>
                  </text>
                  <effectiveTime>
                    <low value="20180622000000+0100"/>
                  </effectiveTime>
                  <value value="59" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile60" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei60"/>
                  </text>
                  <effectiveTime>
                    <low value="20180702000000+0100"/>
                  </effectiveTime>
                  <value value="60" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile61" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei61"/>
                  </text>
                  <effectiveTime>
                    <low value="20210910000000+0100"/>
                  </effectiveTime>
                  <value value="61" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile62" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei62"/>
                  </text>
                  <effectiveTime>
                    <low value="20210910000000+0100"/>
                  </effectiveTime>
                  <value value="62" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile63" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei63"/>
                  </text>
                  <effectiveTime>
                    <low value="20210910000000+0100"/>
                  </effectiveTime>
                  <value value="63" xsi:type="INT"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile64" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei64"/>
                  </text>
                  <effectiveTime>
                    <low value="20210910000000+0100"/>
                  </effectiveTime>
                  <value value="64" xsi:type="INT"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med1" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med2">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>TANTUM VERDE-LÖSUNG ZUM GURGELN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med3">2425092</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>1000 ml</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>187 (A01AD02)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1,5-0-1-0</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>14.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>19.6 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med4">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>&quot;APONOVA&quot; SCHLAF- UND BERUHIGUNGSDRAGEES</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med5">2473932</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>921 (N05CM09)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>8.6 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>12.3 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.9"/>
              <text>
                <reference value="#med2"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2425092" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="TANTUM-VERDE GURGLSG">
                      <originalText>
                        <reference value="#med3"/>
                      </originalText>
                    </code>
                    <name>TANTUM VERDE-LÖSUNG ZUM GURGELN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="1000"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="A01AD02" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="187"/>
                        <pharm:name>187</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="1"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACM"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1.5"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="2"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACV"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med2" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="14.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="19.6" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med4"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2473932" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="SCHLAF+BERUHIGUNG DRG &quot;APO&quot;">
                      <originalText>
                        <reference value="#med5"/>
                      </originalText>
                    </code>
                    <name>&quot;APONOVA&quot; SCHLAF- UND BERUHIGUNGSDRAGEES</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N05CM09" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="921"/>
                        <pharm:name>921</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med4" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="8.6" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="12.3" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med6" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med7">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>PARTOBULIN SDF 1.250 I.E.-FERTIGSPRITZE</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med8">2441168</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>1 ml</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>815 (J06BB01)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>53.2 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>73.09 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med9">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>CALMURID-SALBE</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med10">66453</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>50 g</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>886 (D02AE51)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>3.55 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>5.1 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med7"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2441168" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="PARTOBULIN-SDF FSPR 1250IE">
                      <originalText>
                        <reference value="#med8"/>
                      </originalText>
                    </code>
                    <name>PARTOBULIN SDF 1.250 I.E.-FERTIGSPRITZE</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="1"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="J06BB01" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="815"/>
                        <pharm:name>815</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med7" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="53.2" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="73.09" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med9"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="66453" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="CALMURID SLB">
                      <originalText>
                        <reference value="#med10"/>
                      </originalText>
                    </code>
                    <name>CALMURID-SALBE</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="50"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="D02AE51" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="886"/>
                        <pharm:name>886</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="2"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med9" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="3.55" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="5.1" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med11" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med12">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>AGNOFEM-TROPFEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med13">3534245</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 ml</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td> (G02CX)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1-0-1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>14.2 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>19.8 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.9"/>
              <text>
                <reference value="#med12"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3534245" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="AGNOFEM TR">
                      <originalText>
                        <reference value="#med13"/>
                      </originalText>
                    </code>
                    <name>AGNOFEM-TROPFEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="G02CX" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName=""/>
                        <pharm:name></pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="1"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACM"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="2"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACV"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med12" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="14.2" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="19.8" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med14" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med15">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>BALNEUM HERMAL-BADEZUSATZ</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med16">1266237</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>1 ml</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>855 (D11AX)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>2.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>2.9 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med17">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>ACTIRA 400 mg-FILMTABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med18">3530655</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>5 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1278 (J01MA14)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1-0,5</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>0.0 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>0.0 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med15"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1266237" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="BALNEUM-HERMAL BADEZUSATZ">
                      <originalText>
                        <reference value="#med16"/>
                      </originalText>
                    </code>
                    <name>BALNEUM HERMAL-BADEZUSATZ</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="1"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="D11AX" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="855"/>
                        <pharm:name>855</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med15" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="2.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="2.9" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med17"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3530655" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="ACTIRA FTBL 400MG">
                      <originalText>
                        <reference value="#med18"/>
                      </originalText>
                    </code>
                    <name>ACTIRA 400 mg-FILMTABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="5"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="J01MA14" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1278"/>
                        <pharm:name>1278</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="0.75"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med17" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="0.0" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="0.0" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med19" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med20">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>UROFLO 5 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med21">1285252</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>28 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1797 (G04CA03)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>7.75 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>11.1 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med22">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>DAFLON 500 mg-FILMTABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med23">1287400</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>30 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>596 (C05CA53)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>6.2 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>8.9 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med20"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1285252" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="UROFLO TBL 5MG">
                      <originalText>
                        <reference value="#med21"/>
                      </originalText>
                    </code>
                    <name>UROFLO 5 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="28"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="G04CA03" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1797"/>
                        <pharm:name>1797</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med20" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="7.75" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="11.1" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med22"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1287400" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="DAFLON FTBL 500MG">
                      <originalText>
                        <reference value="#med23"/>
                      </originalText>
                    </code>
                    <name>DAFLON 500 mg-FILMTABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="30"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="C05CA53" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="596"/>
                        <pharm:name>596</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med22" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="6.2" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="8.9" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med24" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med25">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>ALK WÄSSERIG SQ (Fortsetzungsbehandlung)</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med26">437694</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>1 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>55 (V01AA20)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>123 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>170.09 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med27">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>BLOPRESS 16 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med28">1318721</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>28 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>303 (C09CA06)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>2x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>7.2 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>10.25 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med25"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="437694" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="ALK-WAESSERIG SQ-FORTS BEH.">
                      <originalText>
                        <reference value="#med26"/>
                      </originalText>
                    </code>
                    <name>ALK WÄSSERIG SQ (Fortsetzungsbehandlung)</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="1"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="V01AA20" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="55"/>
                        <pharm:name>55</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med25" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="123" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="170.09" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med27"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1318721" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="BLOPRESS TBL 16MG">
                      <originalText>
                        <reference value="#med28"/>
                      </originalText>
                    </code>
                    <name>BLOPRESS 16 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="28"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="C09CA06" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="303"/>
                        <pharm:name>303</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="2"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med27" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="7.2" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="10.25" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med29" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med30">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>ISOGLAUCON ¼%-AUGENTROPFEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med31">1290603</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>10 ml</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>463 (S01EA04)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>4.8 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>6.9 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med30"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1290603" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="ISOGLAUCON AUGTR 0,25PCT">
                      <originalText>
                        <reference value="#med31"/>
                      </originalText>
                    </code>
                    <name>ISOGLAUCON ¼%-AUGENTROPFEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="10"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="S01EA04" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="463"/>
                        <pharm:name>463</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med30" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="4.8" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="6.9" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med32" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med33">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>5-FLUOROURACIL EBEWE 50 mg/ml-KONZENTRAT ZUR HERSTELLUNG EINER INFUSIONSLÖSUNG</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med34">1343771</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>5 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>760 (L01BC02)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>16.75 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>23.4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med33"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1343771" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="5-FLUOROURACIL KONZ 50 &quot;EBE*">
                      <originalText>
                        <reference value="#med34"/>
                      </originalText>
                    </code>
                    <name>5-FLUOROURACIL EBEWE 50 mg/ml-KONZENTRAT ZUR HERSTELLUNG EINER INFUSIONSLÖSUNG</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="5"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01BC02" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="760"/>
                        <pharm:name>760</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med33" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="16.75" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="23.4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med35" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med36">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>KOCHSALZ &quot;BRAUN&quot; 0,9%-INFUSIONSLÖSUNG</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med37">1564963</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>10 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1310 (B05BB01)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1-1-1-0</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>16.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>23.25 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.9"/>
              <text>
                <reference value="#med36"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1564963" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="KOCHSALZ ILSG 0,9PCT &quot;BRA&quot;">
                      <originalText>
                        <reference value="#med37"/>
                      </originalText>
                    </code>
                    <name>KOCHSALZ &quot;BRAUN&quot; 0,9%-INFUSIONSLÖSUNG</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="10"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="B05BB01" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1310"/>
                        <pharm:name>1310</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="1"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACM"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="2"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACD"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="3"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACV"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med36" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="16.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="23.25" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med38" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>11.12.2011</td>
                  <td>10.01.2012</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med39">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>INSULATARD FLEXPEN 100 INTERNATIONALE EINHEITEN/ML-INJEKTIONSSUSPENSION IN EINEM FERTIGPEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med40">2451586</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>5 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>968 (A10AC01)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>40.9 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>56.2 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>ja</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med41">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>ISOZID-H FARBLOS-ALKOHOLISCHE LÖSUNG ZUR HAUTDESINFEKTION</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med42">743190</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>1 ml</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>909 (D08AX53)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>2x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>5.7 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>ja</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med39"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2451586" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="INS INSULAT-FLEXPEN IJSUS">
                      <originalText>
                        <reference value="#med40"/>
                      </originalText>
                    </code>
                    <name>INSULATARD FLEXPEN 100 INTERNATIONALE EINHEITEN/ML-INJEKTIONSSUSPENSION IN EINEM FERTIGPEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="5"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="A10AC01" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="968"/>
                        <pharm:name>968</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med39" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="40.9" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="56.2" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="true" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med41"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="743190" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="ISOZID-H LSG FARBLO">
                      <originalText>
                        <reference value="#med42"/>
                      </originalText>
                    </code>
                    <name>ISOZID-H FARBLOS-ALKOHOLISCHE LÖSUNG ZUR HAUTDESINFEKTION</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="1"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="D08AX53" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="909"/>
                        <pharm:name>909</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="2"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med41" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="5.7" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="true" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med43" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>07.02.2012</td>
                  <td>08.03.2012</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med44">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LYSODREN 500 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med45">2459642</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>2179 (L01XX23)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x tgl.</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Zusatzinformationen für Patienten</td>
                  <td ID="med46">DG: Disassoziative Persönlichkeitsspaltung</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>648.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>887.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med47">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>FUNGORAL 2%-MEDIZINISCHES SHAMPOO</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med48">1319867</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>60 ml</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1031 (D01AC08)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>2-0-0-1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>5 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>7.15 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med49">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>MOMENTUM 500 mg-KAPSELN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med50">976540</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>20 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1445 (N02BE01)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>3.4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>4.84 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med44"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2459642" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LYSODREN TBL 500MG">
                      <originalText>
                        <reference value="#med45"/>
                      </originalText>
                    </code>
                    <name>LYSODREN 500 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01XX23" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="2179"/>
                        <pharm:name>2179</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
              <entryRelationship inversionInd="true" typeCode="COMP">
                <act classCode="ACT" moodCode="INT">
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.49"/>
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.3"/>
                  <code code="PINSTRUCT" codeSystem="1.3.6.1.4.1.19376.1.5.3.2" codeSystemName="IHEActCode"/>
                  <text>
                    <reference value="#med46"/>
                  </text>
                  <statusCode code="completed"/>
                  <entryRelationship inversionInd="true" typeCode="SUBJ">
                    <act classCode="ACT" moodCode="INT">
                      <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.0.3.1"/>
                      <code code="ZINFO" codeSystem="1.2.40.0.34.5.103" codeSystemName="ELGA_ActCode"/>
                      <text>
                        <reference value="#med46"/>
                      </text>
                      <statusCode code="completed"/>
                    </act>
                  </entryRelationship>
                </act>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med44" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="648.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="887.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.9"/>
              <text>
                <reference value="#med47"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1319867" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="FUNGORAL MEDSHP 2PCT">
                      <originalText>
                        <reference value="#med48"/>
                      </originalText>
                    </code>
                    <name>FUNGORAL 2%-MEDIZINISCHES SHAMPOO</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="60"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="D01AC08" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1031"/>
                        <pharm:name>1031</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="1"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACM"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="2"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="2"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="HS"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med47" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="5" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="7.15" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med49"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="976540" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="MOMENTUM KPS 500MG">
                      <originalText>
                        <reference value="#med50"/>
                      </originalText>
                    </code>
                    <name>MOMENTUM 500 mg-KAPSELN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="20"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N02BE01" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1445"/>
                        <pharm:name>1445</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med49" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="3.4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="4.84" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med51" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med52">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>KOCHSALZ &quot;BRAUN&quot; 0,9%-INFUSIONSLÖSUNG</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med53">1564963</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>10 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1310 (B05BB01)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1-1-1-0</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>16.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>23.25 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.9"/>
              <text>
                <reference value="#med52"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1564963" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="KOCHSALZ ILSG 0,9PCT &quot;BRA&quot;">
                      <originalText>
                        <reference value="#med53"/>
                      </originalText>
                    </code>
                    <name>KOCHSALZ &quot;BRAUN&quot; 0,9%-INFUSIONSLÖSUNG</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="10"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="B05BB01" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1310"/>
                        <pharm:name>1310</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="1"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACM"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="2"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACD"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="3"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACV"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med52" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="16.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="23.25" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med54" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Privatrezept</td>
                  <td></td>
                  <td>30.03.2022</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med55">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>5-FLUOROURACIL EBEWE 50 mg/ml-KONZENTRAT ZUR HERSTELLUNG EINER INFUSIONSLÖSUNG</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med56">1343771</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>5 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>760 (L01BC02)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>16.75 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>23.4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med55"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1343771" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="5-FLUOROURACIL KONZ 50 &quot;EBE*">
                      <originalText>
                        <reference value="#med56"/>
                      </originalText>
                    </code>
                    <name>5-FLUOROURACIL EBEWE 50 mg/ml-KONZENTRAT ZUR HERSTELLUNG EINER INFUSIONSLÖSUNG</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="5"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01BC02" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="760"/>
                        <pharm:name>760</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med55" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="16.75" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="23.4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med57" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>08.06.2012</td>
                  <td>08.07.2012</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med58">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>SEROQUEL 4-TAGE STARTERPACKUNG</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med59">1349495</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>10 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1603 (N05AH04)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>5.55 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>7.95 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med58"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1349495" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="SEROQUEL-4-TAGE START-PK">
                      <originalText>
                        <reference value="#med59"/>
                      </originalText>
                    </code>
                    <name>SEROQUEL 4-TAGE STARTERPACKUNG</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="10"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N05AH04" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1603"/>
                        <pharm:name>1603</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med58" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="5.55" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="7.95" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med60" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>08.06.2012</td>
                  <td>08.07.2012</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med61">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LYSODREN 500 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med62">2459642</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>2179 (L01XX23)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>648.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>887.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med61"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2459642" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LYSODREN TBL 500MG">
                      <originalText>
                        <reference value="#med62"/>
                      </originalText>
                    </code>
                    <name>LYSODREN 500 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01XX23" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="2179"/>
                        <pharm:name>2179</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med61" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="648.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="887.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med63" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>16.09.2012</td>
                  <td>16.10.2012</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med64">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>MOMENTUM 500 mg-KAPSELN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med65">976540</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>20 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1445 (N02BE01)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>3.4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>4.84 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med66">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LYSODREN 500 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med67">2459642</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>2179 (L01XX23)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x tgl.</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Zusatzinformationen für Patienten</td>
                  <td ID="med68">DG: Disassoziative Persönlichkeitsspaltung</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>648.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>887.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med69">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>FUNGORAL 2%-MEDIZINISCHES SHAMPOO</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med70">1319867</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>60 ml</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1031 (D01AC08)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>2-0-0-1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>5 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>7.15 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med64"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="976540" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="MOMENTUM KPS 500MG">
                      <originalText>
                        <reference value="#med65"/>
                      </originalText>
                    </code>
                    <name>MOMENTUM 500 mg-KAPSELN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="20"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N02BE01" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1445"/>
                        <pharm:name>1445</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med64" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="3.4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="4.84" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med66"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2459642" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LYSODREN TBL 500MG">
                      <originalText>
                        <reference value="#med67"/>
                      </originalText>
                    </code>
                    <name>LYSODREN 500 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01XX23" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="2179"/>
                        <pharm:name>2179</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
              <entryRelationship inversionInd="true" typeCode="COMP">
                <act classCode="ACT" moodCode="INT">
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.49"/>
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.3"/>
                  <code code="PINSTRUCT" codeSystem="1.3.6.1.4.1.19376.1.5.3.2" codeSystemName="IHEActCode"/>
                  <text>
                    <reference value="#med68"/>
                  </text>
                  <statusCode code="completed"/>
                  <entryRelationship inversionInd="true" typeCode="SUBJ">
                    <act classCode="ACT" moodCode="INT">
                      <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.0.3.1"/>
                      <code code="ZINFO" codeSystem="1.2.40.0.34.5.103" codeSystemName="ELGA_ActCode"/>
                      <text>
                        <reference value="#med68"/>
                      </text>
                      <statusCode code="completed"/>
                    </act>
                  </entryRelationship>
                </act>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med66" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="648.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="887.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.9"/>
              <text>
                <reference value="#med69"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1319867" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="FUNGORAL MEDSHP 2PCT">
                      <originalText>
                        <reference value="#med70"/>
                      </originalText>
                    </code>
                    <name>FUNGORAL 2%-MEDIZINISCHES SHAMPOO</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="60"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="D01AC08" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1031"/>
                        <pharm:name>1031</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="1"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="ACM"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="2"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="2"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="HS"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="1"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med69" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="5" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="7.15" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med71" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>07.10.2012</td>
                  <td>06.11.2012</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med72">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LYSODREN 500 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med73">2459642</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>2179 (L01XX23)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>648.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>887.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med72"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2459642" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LYSODREN TBL 500MG">
                      <originalText>
                        <reference value="#med73"/>
                      </originalText>
                    </code>
                    <name>LYSODREN 500 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01XX23" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="2179"/>
                        <pharm:name>2179</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med72" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="648.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="887.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med74" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>04.03.2013</td>
                  <td>03.04.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med75">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LYSODREN 500 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med76">2459642</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>2179 (L01XX23)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>648.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>887.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med75"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2459642" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LYSODREN TBL 500MG">
                      <originalText>
                        <reference value="#med76"/>
                      </originalText>
                    </code>
                    <name>LYSODREN 500 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01XX23" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="2179"/>
                        <pharm:name>2179</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med75" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="648.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="887.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med77" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>09.07.2013</td>
                  <td>08.08.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med78">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LYSODREN 500 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med79">2459642</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>2179 (L01XX23)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>648.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>887.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med78"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2459642" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LYSODREN TBL 500MG">
                      <originalText>
                        <reference value="#med79"/>
                      </originalText>
                    </code>
                    <name>LYSODREN 500 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01XX23" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="2179"/>
                        <pharm:name>2179</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med78" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="648.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="887.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med80" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>10.07.2013</td>
                  <td>09.08.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med81">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>GEWACALM 10 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med82">565015</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>25 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>553 (N05BA01)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>0-0-0-3</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>4.45 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>6.4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.9"/>
              <text>
                <reference value="#med81"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="565015" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="GEWACALM TBL 10MG">
                      <originalText>
                        <reference value="#med82"/>
                      </originalText>
                    </code>
                    <name>GEWACALM 10 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="25"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N05BA01" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="553"/>
                        <pharm:name>553</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <entryRelationship typeCode="COMP">
                <sequenceNumber value="1"/>
                <substanceAdministration classCode="SBADM" moodCode="INT">
                  <effectiveTime xsi:type="EIVL_TS">
                    <event code="HS"/>
                    <offset unit="s" value="0"/>
                    <doseQuantity value="3"/>
                    <consumable>
                      <manufacturedProduct>
                        <manufacturedMaterial nullFlavor="NA"/>
                      </manufacturedProduct>
                    </consumable>
                  </effectiveTime>
                </substanceAdministration>
              </entryRelationship>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med81" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="4.45" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="6.4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med83" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>22.07.2013</td>
                  <td>21.08.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med84">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>MOMENTUM 500 mg-KAPSELN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med85">976540</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>20 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1445 (N02BE01)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>3.4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>4.84 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med84"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="976540" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="MOMENTUM KPS 500MG">
                      <originalText>
                        <reference value="#med85"/>
                      </originalText>
                    </code>
                    <name>MOMENTUM 500 mg-KAPSELN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="20"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N02BE01" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1445"/>
                        <pharm:name>1445</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med84" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="3.4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="4.84" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med86" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>22.07.2013</td>
                  <td>21.08.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med87">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>HELOPANFLAT-DRAGEES</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med88">1268785</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>20 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1434 (A09AA02)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>4x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>1.9 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>2.7 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med87"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1268785" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="HELOPANFLAT DRG">
                      <originalText>
                        <reference value="#med88"/>
                      </originalText>
                    </code>
                    <name>HELOPANFLAT-DRAGEES</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="20"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="A09AA02" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1434"/>
                        <pharm:name>1434</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="4"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med87" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="1.9" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="2.7" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med89" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>15.09.2013</td>
                  <td>15.10.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med90">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>ALENDRONSÄURE ACTAVIS 70 mg-EINMAL WÖCHENTLICH TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med91">3538852</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>4 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>49 (M05BA04)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1x wö</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>0.0 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>0.0 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med92">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>BRADOSOL-LUTSCHTABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med93">8332</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>40 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td> (R02AA20)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>4x tgl</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>2.25 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>3.25 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med90"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3538852" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="ALENDRONS-1xWÖ TBL 70 &quot;ACT&quot;">
                      <originalText>
                        <reference value="#med91"/>
                      </originalText>
                    </code>
                    <name>ALENDRONSÄURE ACTAVIS 70 mg-EINMAL WÖCHENTLICH TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="4"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="M05BA04" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="49"/>
                        <pharm:name>49</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="w" value="1"/>
              </effectiveTime>
              <doseQuantity value="1"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med90" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="0.0" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="0.0" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med92"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="8332" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="BRADOSOL LTBL">
                      <originalText>
                        <reference value="#med93"/>
                      </originalText>
                    </code>
                    <name>BRADOSOL-LUTSCHTABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="40"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="R02AA20" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName=""/>
                        <pharm:name></pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="4"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med92" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="2.25" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="3.25" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med94" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>31.10.2013</td>
                  <td>30.11.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med95">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LEVETIRACETAM GENERICON 500 mg-FILMTABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med96">3903116</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>60 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1081 (N03AX14)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>23.5 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>31.7 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med97">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>CANDESARTAN ACTAVIS 8 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med98">3785970</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>30 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>303 (C09CA06)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>2x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>6.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>9.5 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med99">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LYSODREN 500 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med100">2459642</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>2179 (L01XX23)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>648.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>887.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med95"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3903116" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LEVETIRACETAM FTBL 500 &quot;GEN&quot;">
                      <originalText>
                        <reference value="#med96"/>
                      </originalText>
                    </code>
                    <name>LEVETIRACETAM GENERICON 500 mg-FILMTABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="60"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N03AX14" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1081"/>
                        <pharm:name>1081</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med95" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="23.5" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="31.7" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med97"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3785970" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="CANDESARTAN TBL 8MG &quot;ACT&quot;">
                      <originalText>
                        <reference value="#med98"/>
                      </originalText>
                    </code>
                    <name>CANDESARTAN ACTAVIS 8 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="30"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="C09CA06" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="303"/>
                        <pharm:name>303</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="2"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="2"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med97" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="6.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="9.5" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med99"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2459642" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LYSODREN TBL 500MG">
                      <originalText>
                        <reference value="#med100"/>
                      </originalText>
                    </code>
                    <name>LYSODREN 500 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01XX23" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="2179"/>
                        <pharm:name>2179</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med99" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="648.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="887.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med101" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>01.11.2013</td>
                  <td>01.12.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med102">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LEVETIRACETAM GENERICON 500 mg-FILMTABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med103">3903116</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>60 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1081 (N03AX14)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>23.5 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>31.7 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med102"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3903116" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LEVETIRACETAM FTBL 500 &quot;GEN&quot;">
                      <originalText>
                        <reference value="#med103"/>
                      </originalText>
                    </code>
                    <name>LEVETIRACETAM GENERICON 500 mg-FILMTABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="60"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N03AX14" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1081"/>
                        <pharm:name>1081</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med102" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="23.5" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="31.7" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med104" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>01.11.2013</td>
                  <td>01.12.2013</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med105">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>CANDESARTAN ACTAVIS 8 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med106">3785970</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>30 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>303 (C09CA06)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>2x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>6.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>9.5 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med105"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3785970" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="CANDESARTAN TBL 8MG &quot;ACT&quot;">
                      <originalText>
                        <reference value="#med106"/>
                      </originalText>
                    </code>
                    <name>CANDESARTAN ACTAVIS 8 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="30"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="C09CA06" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="303"/>
                        <pharm:name>303</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="2"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="2"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med105" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="6.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="9.5" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med107" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>02.06.2016</td>
                  <td>02.07.2016</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med108">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LEVETIRACETAM GENERICON 500 mg-FILMTABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med109">3903116</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>60 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1081 (N03AX14)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>23.5 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>31.7 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med110">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>CANDESARTAN ACTAVIS 8 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med111">3785970</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>30 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>303 (C09CA06)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>2x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>6.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>9.5 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med112">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>LYSODREN 500 mg-TABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med113">2459642</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>100 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>2179 (L01XX23)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>648.05 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>887.65 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med108"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3903116" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LEVETIRACETAM FTBL 500 &quot;GEN&quot;">
                      <originalText>
                        <reference value="#med109"/>
                      </originalText>
                    </code>
                    <name>LEVETIRACETAM GENERICON 500 mg-FILMTABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="60"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="N03AX14" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1081"/>
                        <pharm:name>1081</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med108" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="23.5" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="31.7" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med110"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3785970" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="CANDESARTAN TBL 8MG &quot;ACT&quot;">
                      <originalText>
                        <reference value="#med111"/>
                      </originalText>
                    </code>
                    <name>CANDESARTAN ACTAVIS 8 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="30"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="C09CA06" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="303"/>
                        <pharm:name>303</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="2"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="2"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med110" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="6.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="9.5" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med112"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="2459642" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="LYSODREN TBL 500MG">
                      <originalText>
                        <reference value="#med113"/>
                      </originalText>
                    </code>
                    <name>LYSODREN 500 mg-TABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="100"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01XX23" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="2179"/>
                        <pharm:name>2179</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="1"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med112" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="648.05" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="887.65" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med114" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>07.05.2021</td>
                  <td>06.06.2021</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med115">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>5-FLUOROURACIL EBEWE 50 mg/ml-KONZENTRAT ZUR HERSTELLUNG EINER INFUSIONSLÖSUNG</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med116">1343771</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>5 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>760 (L01BC02)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>16.75 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>23.4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med115"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1343771" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="5-FLUOROURACIL KONZ 50 &quot;EBE*">
                      <originalText>
                        <reference value="#med116"/>
                      </originalText>
                    </code>
                    <name>5-FLUOROURACIL EBEWE 50 mg/ml-KONZENTRAT ZUR HERSTELLUNG EINER INFUSIONSLÖSUNG</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="5"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01BC02" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="760"/>
                        <pharm:name>760</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med115" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="16.75" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="23.4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.101"/>
          <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.2.1"/>
          <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.2.1"/>
          <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.3.19"/>
          <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.8"/>
          <id assigningAuthorityName="Dr. Test Osteron" extension="med117" root="1.2.40.0.34.99.9999.9.99"/>
          <code code="57828-6" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC"/>
          <title>Rezept</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Rezeptart</th>
                  <th>Gültig ab</th>
                  <th>Gültig bis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kassenrezept</td>
                  <td>07.05.2021</td>
                  <td>06.06.2021</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med118">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>5-FLUOROURACIL EBEWE 50 mg/ml-KONZENTRAT ZUR HERSTELLUNG EINER INFUSIONSLÖSUNG</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med119">1343771</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>5 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>760 (L01BC02)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>16.75 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>23.4 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table ID="med120">
              <tbody>
                <tr>
                  <td>Verordnung</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>VerordnungsID</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Arzneibezeichnung</td>
                  <td>ACTIRA 400 mg-FILMTABLETTEN</td>
                </tr>
                <tr>
                  <td>Arznei-Pharmazentralnummer</td>
                  <td ID="med121">3530655</td>
                </tr>
                <tr>
                  <td>Angaben zur Packung</td>
                  <td>5 St.</td>
                </tr>
                <tr>
                  <td>Arzneiwirkstoffname (ATC-Code)</td>
                  <td>1278 (J01MA14)</td>
                </tr>
                <tr>
                  <td>Einnahmestart</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmeende</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Einnahmedauer</td>
                  <td>(unbekannt)</td>
                </tr>
                <tr>
                  <td>Dosierung</td>
                  <td>1-0,5</td>
                </tr>
                <tr>
                  <td>Art der Anwendung</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Therapieart</td>
                  <td>Einzelverordnung</td>
                </tr>
                <tr>
                  <td>Anzahl der Einlösungen</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Anzahl der Packungen</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>TAX-Preis</td>
                  <td>0.0 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Apothekenverkaufspreis</td>
                  <td>0.0 EUR inkl 20% USt</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med118"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="1343771" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="5-FLUOROURACIL KONZ 50 &quot;EBE*">
                      <originalText>
                        <reference value="#med119"/>
                      </originalText>
                    </code>
                    <name>5-FLUOROURACIL EBEWE 50 mg/ml-KONZENTRAT ZUR HERSTELLUNG EINER INFUSIONSLÖSUNG</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="5"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="L01BC02" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="760"/>
                        <pharm:name>760</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="3"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med118" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="16.75" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="23.4" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
          <entry typeCode="DRIV">
            <substanceAdministration classCode="SBADM" moodCode="INT">
              <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.8.1.3.1"/>
              <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.24"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.2"/>
              <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.6"/>
              <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.1"/>
              <text>
                <reference value="#med120"/>
              </text>
              <statusCode code="completed"/>
              <effectiveTime xsi:type="IVL_TS">
                <low nullFavor="UNK"/>
                <high nullFavor="UNK"/>
              </effectiveTime>
              <repeatNumber nullFlavor="MSK"/>
              <consumable>
                <manufacturedProduct classCode="MANU" xmlnspharm="urnihepharmmedication">
                  <templateId assigningAuthorityName="IHE PCC" root="1.3.6.1.4.1.19376.1.5.3.1.4.7.2"/>
                  <templateId assigningAuthorityName="HL7 CCD" root="2.16.840.1.113883.10.20.1.53"/>
                  <manufacturedMaterial classCode="MMAT" determinerCode="KIND">
                    <templateId assigningAuthorityName="ELGA" root="1.2.40.0.34.11.2.3.4"/>
                    <templateId assigningAuthorityName="IHE PHARM" root="1.3.6.1.4.1.19376.1.9.1.3.1"/>
                    <code code="3530655" codeSystem="1.2.40.0.34.4.16" codeSystemName="Pharmazentralnummer" displayName="ACTIRA FTBL 400MG">
                      <originalText>
                        <reference value="#med121"/>
                      </originalText>
                    </code>
                    <name>ACTIRA 400 mg-FILMTABLETTEN</name>
                    <pharm:asContent classCode="CONT">
                      <pharm:containerPackagedMedicine classCode="CONT" determinerCode="INSTANCE">
                        <pharm:capacityQuantity value="5"/>
                      </pharm:containerPackagedMedicine>
                    </pharm:asContent>
                    <pharm:ingredient classCode="ACTI">
                      <pharm:ingredient classCode="MMAT" determinerCode="KIND">
                        <pharm:code code="J01MA14" codeSystem="2.16.840.1.113883.6.73" codeSystemName="ATC WHO" displayName="1278"/>
                        <pharm:name>1278</pharm:name>
                      </pharm:ingredient>
                    </pharm:ingredient>
                  </manufacturedMaterial>
                </manufacturedProduct>
              </consumable>
              <effectiveTime institutionSpecified="true" operator="A" xsi:type="PIVL_TS">
                <period unit="d" value="1"/>
              </effectiveTime>
              <doseQuantity value="0.75"/>
              <entryRelationship typeCode="COMP">
                <supply classCode="SPLY" moodCode="RQO">
                  <independentInd value="false"/>
                  <quantity value="0"/>
                </supply>
              </entryRelationship>
            </substanceAdministration>
          </entry>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.143"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="med120" root=""/>
              <code code="ArnMittelOrganizer" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel-Organizer"/>
              <statusCode code="completed"/>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.140"/>
                  <code code="ArzMittelPreisTAX" codeSystem="1.2.40.0.34.5.195" displayName="TAX-Preis des Arzneimittels"/>
                  <value unit="EUR" value="0.0" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.141"/>
                  <code code="ArzMittelPreisApo" codeSystem="1.2.40.0.34.5.195" displayName="Apotheken-Verkaufspreis des Arzneimittels"/>
                  <value unit="EUR" value="0.0" xsi:type="PQ"/>
                  <entryRelationship type="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.142"/>
                      <code code="MWST" codeSystem="1.2.40.0.34.5.195" displayName="Mehrwertsteuersatz"/>
                      <value unit="[%]" value="20" xsi:type="PQ"/>
                    </observation>
                  </entryRelationship>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.144"/>
                  <code code="ArnMittelPackArt" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittelpakungsart"/>
                  <value code="ArnMittelPackArt_OP" codeSystem="1.2.40.0.34.5.195" displayName="Originalpackung" xsi:type="CD"/>
                </observation>
              </component>
              <component>
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.149"/>
                  <code code="ArnMittelRezGebFrei" codeSystem="1.2.40.0.34.5.195" displayName="Arzneimittel rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
      </component>
    </structuredBody>
  </component>
</ClinicalDocument>
`;
