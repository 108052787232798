




















































































































import Vue from 'vue';
import Patient from '@/components/Patient.vue';

import parse from '@/ends/ends2-parser';
import { ends2Xml00000036 } from '@/ends/data/00000036';
import { pat00001 } from '@/ends/data/pat00001';
import { pat00003 } from '@/ends/data/pat00003';
import { pat00005 } from '@/ends/data/pat00005';
import { pat00007 } from '@/ends/data/pat00007';
import { pat00009 } from '@/ends/data/pat00009';
import { pat00014 } from '@/ends/data/pat00014';
import { pat00021 } from '@/ends/data/pat00021';
import { pat00042 } from '@/ends/data/pat00042';

export default Vue.extend({
  name: 'App',

  components: { Patient },

  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Geburtsdatum', value: 'birthTime' },
      { text: 'Geschlecht', value: 'gender' },
      { text: 'Wohnadresse', value: 'address' },
    ],
    patients: [
      parse(ends2Xml00000036),
      parse(pat00001),
      parse(pat00003),
      parse(pat00005),
      parse(pat00007),
      parse(pat00009),
      parse(pat00014),
      parse(pat00021),
      parse(pat00042),
    ],
    dialog: false,
    dialogContent: '',
    uploadedCdaFile: null,
  }),

  mounted() {
    console.log('App.data.patients:');
    console.dir(this.patients);
  },

  watch: {
    uploadedCdaFile(val, oldVal) {
      if (!val) return;
      const reader = new FileReader();
      const file = val;
      reader.onload = () => this.importPatient(reader.result as string);
      reader.readAsText(file);
    },
  },

  methods: {
    addPatient(): void {
      const patient = parse(this.dialogContent);
      this.patients.splice(0, 0, patient);
      this.dialogContent = '';
    },
    importPatient(cdaContent: string) {
      console.log(cdaContent.substring(0, 100));
      const patient = parse(cdaContent);
      this.patients.splice(0, 0, patient);
    },
  },
});
