/* eslint-disable */
export const pat00042 = `
<?xml-stylesheet type="text/xsl" href="ELGA_Stylesheet.xsl"?>
<ClinicalDocument xmlns:hl7at="urn:hl7-at:v3" xmlns:pharm="urn:ihe:pharm:medication" xmlns:sdtc="urn:hl7-org:sdtc" xmlns="urn:hl7-org:v3" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <realmCode code="AT"/>
  <typeId extension="POCD_HD000040" root="2.16.840.1.113883.1.3"/>
  <templateId root="1.2.40.0.34.6.0.11.0.1"/>
  <templateId root="1.2.40.0.34.7.25.1"/>
  <templateId root="1.2.40.0.34.6.0.11.0.6"/>
  <id assigningAuthorityName="Dr. Test Osteron" extension="1" root="1.2.40.0.34.99.9999"/>
  <code code="EXNDS_Patientendaten" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Datenbankexport EXNDS - Patientendaten">
    <translation code="DatenbankexportEXNDS" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Datenbankexport EXNDS"/>
  </code>
  <title>Datenbankexport</title>
  <hl7at:terminologyDate value="20220101"/>
  <hl7at:formatCode code="urn:hl7-at:exnds-patient:2021"/>
  <hl7at:practiceSettingCode code="F001" codeSystem="1.2.40.0.34.5.12"/>
  <effectiveTime value="20220228214615+0100"/>
  <confidentialityCode code="N" codeSystem="2.16.840.1.113883.5.25" codeSystemName="HL7:Confidentiality" displayName="normal"/>
  <languageCode code="de-AT"/>
  <setId assigningAuthorityName="Dr. Test Osteron" extension="1" root="1.2.40.0.34.99.9999"/>
  <versionNumber value="1"/>
  <recordTarget>
    <patientRole>
      <id assigningAuthorityName="Dr. Test Osteron" extension="PAT00042" root="1.2.40.0.34.99.9999.1.2"/>
      <id assigningAuthorityName="Österreichische Sozialversicherung" nullFlavor="UNK" root="1.2.40.0.10.1.4.3.1"/>
      <addr use="H">
        <streetAddressLine>Kollweg 19</streetAddressLine>
        <postalCode>4320</postalCode>
        <city>Perg</city>
        <country>AUT</country>
      </addr>
      <patient>
        <name>
          <given>Maria</given>
          <family>Muster</family>
        </name>
        <administrativeGenderCode code="F" codeSystem="2.16.840.1.113883.5.1" codeSystemName="HL7:AdministrativeGender" displayName="Weibliche Person"/>
        <birthTime value="19210319"/>
      </patient>
    </patientRole>
  </recordTarget>
  <author>
    <functionCode code="OL" codeSystem="1.2.40.0.34.99.9999.10.1" codeSystemName="doctory.elga.cda.Funktionscodes" displayName="Ärztliche Ordinationsleitung"/>
    <time value="20220228214615+0100"/>
    <assignedAuthor>
      <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
      <code code="107" codeSystem="1.2.40.0.34.5.160" codeSystemName="gda-rollen" displayName="Fachärztin/Facharzt für Chirurgie"/>
      <telecom use="WP" value="mailto:max@muster.com"/>
      <assignedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </assignedPerson>
      <representedOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedOrganization>
    </assignedAuthor>
  </author>
  <custodian>
    <assignedCustodian>
      <representedCustodianOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedCustodianOrganization>
    </assignedCustodian>
  </custodian>
  <legalAuthenticator>
    <time value="20220228214615+0100"/>
    <signatureCode code="S"/>
    <assignedEntity>
      <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
      <telecom use="WP" value="mailto:max@muster.com"/>
      <assignedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </assignedPerson>
      <representedOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </representedOrganization>
    </assignedEntity>
  </legalAuthenticator>
  <participant typeCode="CALLBCK">
    <templateId root="1.2.40.0.34.6.0.11.1.20"/>
    <associatedEntity classCode="PROV">
      <telecom use="WP" value="mailto:max@muster.com"/>
      <associatedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Test</given>
          <family>Osteron</family>
        </name>
      </associatedPerson>
      <scopingOrganization>
        <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
        <name>Dr. Test Osteron</name>
        <telecom value="mailto:max@muster.com"/>
        <telecom value="www.osteron-praxis.at"/>
        <addr>
          <streetAddressLine>Im Walde 55</streetAddressLine>
          <postalCode>6999</postalCode>
          <city>Woimmer</city>
          <country>AUT</country>
        </addr>
      </scopingOrganization>
    </associatedEntity>
  </participant>
  <participant typeCode="IND">
    <templateId root="1.2.40.0.34.6.0.11.1.23"/>
    <functionCode code="PCP" codeSystem="2.16.840.1.113883.5.88" codeSystemName="HL7:ParticipationFunction" displayName="Hausarzt"/>
    <associatedEntity classCode="PROV">
      <associatedPerson>
        <name>
          <prefix>Dr.</prefix>
          <given>Horst</given>
          <family>Schmied</family>
        </name>
      </associatedPerson>
      <scopingOrganization>
        <id nullFlavor="UNK"/>
        <name>Dr. Horst Schmied</name>
        <addr>
          <streetAddressLine></streetAddressLine>
          <postalCode></postalCode>
          <city></city>
          <country>AUT</country>
        </addr>
      </scopingOrganization>
    </associatedEntity>
  </participant>
  <componentOf>
    <encompassingEncounter>
      <code code="AMB" codeSystem="2.16.840.1.113883.5.4" codeSystemName="HL7:ActCode" displayName="ambulant"/>
      <effectiveTime>
        <low value=""/>
        <high value=""/>
      </effectiveTime>
      <responsibleParty>
        <assignedEntity>
          <id assigningAuthorityName="Dr. Test Osteron" extension="test" root="1.2.40.0.34.99.9999.3.3"/>
          <telecom use="WP" value="mailto:max@muster.com"/>
          <assignedPerson>
            <name>
              <prefix>Dr.</prefix>
              <given>Test</given>
              <family>Osteron</family>
            </name>
          </assignedPerson>
        </assignedEntity>
      </responsibleParty>
      <location>
        <healthCareFacility>
          <code code="158" codeSystem="1.2.40.0.34.5.2" displayName="Fachärztin/Facharzt"/>
          <serviceProviderOrganization>
            <id assigningAuthorityName="GDA Index" root="1.2.40.0.34.99.9999"/>
            <name>Dr. Test Osteron</name>
            <telecom value="mailto:max@muster.com"/>
            <telecom value="www.osteron-praxis.at"/>
            <addr>
              <streetAddressLine>Im Walde 55</streetAddressLine>
              <postalCode>6999</postalCode>
              <city>Woimmer</city>
              <country>AUT</country>
            </addr>
          </serviceProviderOrganization>
        </healthCareFacility>
      </location>
    </encompassingEncounter>
  </componentOf>
  <component>
    <structuredBody>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.66"/>
          <code code="PAT_INFO_ADM" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Patienteninformation - Administrativ"/>
          <title>Weitere Patienteninformation – Administrativ</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Eigenschaft</th>
                  <th>Wert</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Versichertenkategorie</td>
                  <td>Pensionist</td>
                </tr>
                <tr>
                  <td>Beruf</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Rezeptgebührenbefreit</td>
                  <td>nein</td>
                </tr>
                <tr>
                  <td>Entfernung in km</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Bundeslandcode</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Erstzuweiser</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.63"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="PatInfoAdOrg" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Patienteninformation Administrativ Organizer"/>
              <statusCode code="completed"/>
              <effectiveTime>
                <low value="20220228214615+0100"/>
              </effectiveTime>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.107"/>
                  <code code="VKT" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Versichertenkategorie"/>
                  <value value="VKT05" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.108"/>
                  <code code="BER" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Beruf"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.109"/>
                  <code code="RZG" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Rezeptgebührenbefreit"/>
                  <value value="false" xsi:type="BL"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.110"/>
                  <code code="EKM" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Entfernung in km"/>
                  <value nullFlavor="NA" xsi:type="PQ"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.111"/>
                  <code code="BLD" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Bundeslandcode"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.112"/>
                  <code code="ERSTZUW" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Erstzuweiser"/>
                  <value nullFlavor="NA" xsi:type="CD"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.67"/>
          <code code="PAT_INFO_MED" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Patienteninformation - Medizinisch"/>
          <title>Weitere Patienteninformation – Medizinisch</title>
          <component typeCode="COMP">
            <section>
              <templateId root="1.2.40.0.34.6.0.11.2.46"/>
              <templateId root="2.16.840.1.113883.10.20.1.16"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.3.25"/>
              <templateId root="1.3.6.1.4.1.19376.1.5.3.1.1.5.3.2"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="8716-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Vital signs"/>
              <title>Vitalparameter</title>
              <text>
                <table>
                  <thead>
                    <tr>
                      <th>Vitalparameterart</th>
                      <th>Wert</th>
                      <th>Einheit</th>
                      <th>Datum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="patmed1">
                      <td ID="patmed2">Körpergröße</td>
                      <td>-</td>
                      <td>cm</td>
                      <td>-</td>
                    </tr>
                    <tr ID="patmed3">
                      <td ID="patmed4">Körpergewicht</td>
                      <td>-</td>
                      <td>kg</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <organizer classCode="CLUSTER" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.23"/>
                  <templateId extension="2015-08-01" root="2.16.840.1.113883.10.20.22.4.26"/>
                  <templateId extension="2015-11-19" root="2.16.840.1.113883.10.20.36.2"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="patmed" root="1.2.40.0.34.99.9999.6.99"/>
                  <code code="46680005" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low nullFlavor="UNK"/>
                    <high nullFlavor="UNK"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.24"/>
                      <templateId root="2.16.840.1.113883.10.20.1.31"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13.2"/>
                      <id assigningAuthorityName="Dr. Test Osteron" extension="patmed1" root="1.2.40.0.34.99.9999.7.99"/>
                      <code code="8302-2" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Körpergröße">
                        <originalText>
                          <reference value="#patmed2"/>
                        </originalText>
                      </code>
                      <text>
                        <reference value="#patmed1"/>
                      </text>
                      <statusCode code="completed"/>
                      <effectiveTime>
                        <low nullFlavor="UNK"/>
                        <high nullFlavor="UNK"/>
                      </effectiveTime>
                      <value nullFlavor="NA" unit="cm" xsi:type="PQ"/>
                    </observation>
                  </component>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.24"/>
                      <templateId root="2.16.840.1.113883.10.20.1.31"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13"/>
                      <templateId root="1.3.6.1.4.1.19376.1.5.3.1.4.13.2"/>
                      <id assigningAuthorityName="Dr. Test Osteron" extension="patmed3" root="1.2.40.0.34.99.9999.7.99"/>
                      <code code="3141-9" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="Körpergewicht">
                        <originalText>
                          <reference value="#patmed4"/>
                        </originalText>
                      </code>
                      <text>
                        <reference value="#patmed3"/>
                      </text>
                      <statusCode code="completed"/>
                      <effectiveTime>
                        <low nullFlavor="UNK"/>
                        <high nullFlavor="UNK"/>
                      </effectiveTime>
                      <value nullFlavor="NA" unit="kg" xsi:type="PQ"/>
                    </observation>
                  </component>
                </organizer>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section>
              <templateId root="1.2.40.0.34.6.0.11.2.31"/>
              <code code="PAT_INFO_MED_MERK" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Weitere Merkmale"/>
              <title>Weitere Merkmale</title>
              <text>
                <table>
                  <thead>
                    <tr>
                      <th>Eigenschaft</th>
                      <th>Wert</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Blutgruppe</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Rhesusfaktor</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Besondere Kennzeichen/Hautfarbe</td>
                      <td ID="patmed5">-</td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.65"/>
                  <code code="KOERPERLICHEMERKMALE" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Körperliche Merkmale"/>
                  <statusCode code="completed"/>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.66"/>
                      <code code="365637002" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Finding of AB0 blood group"/>
                      <value codeSystem="2.16.840.1.113883.6.96" nullFlavor="NA" xsi:type="CD"/>
                    </observation>
                  </component>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.64"/>
                      <code code="115758001" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Rh blood group phenotype (finding)"/>
                      <value codeSystem="2.16.840.1.113883.6.96" nullFlavor="NA" xsi:type="CD"/>
                    </observation>
                  </component>
                  <component>
                    <observation classCode="OBS" moodCode="EVN">
                      <templateId root="1.2.40.0.34.6.0.11.3.113"/>
                      <code code="BesKennzeichenHautfarbe" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Besondere Kennzeichen / Hautfarbe"/>
                      <value nullFlavor="NA" xsi:type="CD">
                        <originalText>
                          <reference value="#patmed5"/>
                        </originalText>
                      </value>
                    </observation>
                  </component>
                </organizer>
              </entry>
            </section>
          </component>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.40"/>
          <code code="BEFUNDE" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Befunde"/>
          <title>Befunde</title>
          <text>
            <paragraph styleCode="xELGA_h3">Befund vom 18.05.2012</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td ID="befunde1">Befund (18.05.2012)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 18.05.2012</paragraph>
            Befund vom 18.05.2012 als externe Datei beigelegt
            <paragraph styleCode="xELGA_h3">Befund vom 30.05.2012</paragraph>
            <table>
              <thead>
                <tr>
                  <th>Zeilennummer</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2</td>
                  <td ID="befunde2">Befund (30.05.2012)</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <paragraph styleCode="xELGA_h3">Befund vom 30.05.2012</paragraph>
            Befund vom 30.05.2012 als externe Datei beigelegt
          </text>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde1"/>
                      </text>
                      <effectiveTime>
                        <low value="20120518000000+0100"/>
                      </effectiveTime>
                      <value value="1" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///ROCLIN1_2691479.lab" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundKartEintrag" codeSystem="1.2.40.0.34.5.195" displayName="Befundkarteieintrag"/>
              <entryRelationship typeCode="COMP">
                <organizer classCode="BATTERY" moodCode="EVN">
                  <templateId root="1.2.40.0.34.6.0.11.3.137"/>
                  <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
                  <statusCode code="completed"/>
                  <effectiveTime>
                    <low value="20220228214615+0100"/>
                  </effectiveTime>
                  <component contextConductionInd="true" typeCode="COMP">
                    <observation classCode="OBS" moodCode="EVN" negationInd="false">
                      <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                      <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                      <text>
                        <reference value="#befunde2"/>
                      </text>
                      <effectiveTime>
                        <low value="20120530000000+0100"/>
                      </effectiveTime>
                      <value value="2" xsi:type="INT"/>
                    </observation>
                  </component>
                </organizer>
              </entryRelationship>
            </act>
          </entry>
          <entry typeCode="DRIV">
            <act classCode="ACT" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.150"/>
              <code code="BefundDokument" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Sections" displayName="Befunddokument"/>
              <reference typeCode="REFR">
                <externalDocument>
                  <templateId root="1.2.40.0.34.6.0.11.3.151"/>
                  <id extension="file:///ROCLIN1_2691479-2.lab" root="2.5.4.83"/>
                  <code code="11502-2" codeSystem="2.16.840.1.113883.6.1" displayName="Laboratory report"/>
                </externalDocument>
              </reference>
            </act>
          </entry>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.96"/>
          <code code="439401001" codeSystem="2.16.840.1.113883.6.96" codeSystemName="SNOMED CT" displayName="Diagnosis"/>
          <title>Diagnose</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Zeitraum oder Zeitpunkt</th>
                  <th>Diagnosetext</th>
                  <th>Code [Codesystem]</th>
                  <th>Diagnoseart</th>
                </tr>
              </thead>
              <tbody/>
            </table>
          </text>
        </section>
      </component>
      <component typeCode="COMP">
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 21.03.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="300" codeSystem="1.2.40.0.34.5.11" displayName="Hämatologie"/>
              <title>Hämatologie</title>
              <text>
                <paragraph styleCode="xELGA_h3">Blutbild</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab1">
                      <td>Hämoglobin</td>
                      <td>13.8</td>
                      <td>g/dL</td>
                      <td ID="lab2">12-16</td>
                      <td></td>
                    </tr>
                    <tr ID="lab3">
                      <td>Hämatokrit</td>
                      <td>40.7</td>
                      <td>%</td>
                      <td ID="lab4">35-47</td>
                      <td></td>
                    </tr>
                    <tr ID="lab5">
                      <td>MCH (mittleres zelluläres Hämoglobin)</td>
                      <td>31.1</td>
                      <td>pg</td>
                      <td ID="lab6">27-35</td>
                      <td></td>
                    </tr>
                    <tr ID="lab7">
                      <td>MCHC (mittlere zelluläre Hämoglobinkonzentration)</td>
                      <td>33.8</td>
                      <td>g/dL</td>
                      <td ID="lab8">29-36</td>
                      <td></td>
                    </tr>
                    <tr ID="lab9">
                      <td>MCV (mittleres Zellvolumen)</td>
                      <td>92.0</td>
                      <td>fL</td>
                      <td ID="lab10">80-101</td>
                      <td></td>
                    </tr>
                    <tr ID="lab11">
                      <td>Erythrozyten</td>
                      <td>4.42</td>
                      <td>10*12/L</td>
                      <td ID="lab12">3.8-5.2</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="300" codeSystem="1.2.40.0.34.5.11" displayName="Hämatologie"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="03010" codeSystem="1.2.40.0.34.5.11" displayName="Blutbild"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab1" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="718-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="HGB"/>
                          <text>
                            <reference value="#lab1"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="g/dL" value="13.8" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab2"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="g/dL" value="12"/>
                                <high unit="g/dL" value="16"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab3" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20570-8" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="HCT"/>
                          <text>
                            <reference value="#lab3"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="%" value="40.7" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab4"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="%" value="35"/>
                                <high unit="%" value="47"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab5" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="28539-5" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="MCH"/>
                          <text>
                            <reference value="#lab5"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="pg" value="31.1" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab6"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="pg" value="27"/>
                                <high unit="pg" value="35"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab7" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="28540-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="MCHC"/>
                          <text>
                            <reference value="#lab7"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="g/dL" value="33.8" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab8"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="g/dL" value="29"/>
                                <high unit="g/dL" value="36"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab9" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="30428-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="MCV"/>
                          <text>
                            <reference value="#lab9"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="fL" value="92.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab10"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="fL" value="80"/>
                                <high unit="fL" value="101"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab11" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="26453-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="RBC"/>
                          <text>
                            <reference value="#lab11"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="10*12/L" value="4.42" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab12"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="10*12/L" value="3.8"/>
                                <high unit="10*12/L" value="5.2"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="400" codeSystem="1.2.40.0.34.5.11" displayName="Gerinnung/Hämostaseologie"/>
              <title>Gerinnung/Hämostaseologie</title>
              <text>
                <paragraph styleCode="xELGA_h3">Hämostaseologie Globaltests</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab13">
                      <td>PTZ (Prothrombinzeit)</td>
                      <td>negativ</td>
                      <td>%</td>
                      <td ID="lab14">0-15</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="400" codeSystem="1.2.40.0.34.5.11" displayName="Gerinnung/Hämostaseologie"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="04140" codeSystem="1.2.40.0.34.5.11" displayName="Hämostaseologie Globaltests"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab13" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="5894-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="PROTH"/>
                          <text>
                            <reference value="#lab13"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="%" value="negativ" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab14"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="%" value="0"/>
                                <high unit="%" value="15"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab15" styleCode="xELGA_red">
                      <td>Cholesterin</td>
                      <td>257.0</td>
                      <td>mg/dL</td>
                      <td ID="lab16">100-200</td>
                      <td>++</td>
                    </tr>
                    <tr ID="lab17">
                      <td>Glucose</td>
                      <td>106.0</td>
                      <td>mg/dL</td>
                      <td ID="lab18">60-110</td>
                      <td></td>
                    </tr>
                    <tr ID="lab19" styleCode="xELGA_red">
                      <td>Gamma-GT</td>
                      <td>39.0</td>
                      <td>[iU]/L</td>
                      <td ID="lab20">0-38</td>
                      <td>+</td>
                    </tr>
                    <tr ID="lab21" styleCode="xELGA_red">
                      <td>HDL-Cholesterin</td>
                      <td>53.0</td>
                      <td>mg/dL</td>
                      <td ID="lab22">55-999999</td>
                      <td>-</td>
                    </tr>
                    <tr ID="lab23" styleCode="xELGA_red">
                      <td>Cholesterin/HDL-Cholesterin-Ratio</td>
                      <td>4.8</td>
                      <td>{Ratio}</td>
                      <td ID="lab24">0-3.3</td>
                      <td>++</td>
                    </tr>
                    <tr ID="lab25">
                      <td>Triglyceride</td>
                      <td>146.0</td>
                      <td>mg/dL</td>
                      <td ID="lab26">25-180</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab15" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2093-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="CHOL"/>
                          <text>
                            <reference value="#lab15"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="mg/dL" value="257.0" xsi:type="PQ"/>
                          <interpretationCode code="HH" codeSystem="2.16.840.1.113883.5.83" displayName="oberhalb des Alarmbereiches"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab16"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="100"/>
                                <high unit="mg/dL" value="200"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab17" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2345-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="NBZP"/>
                          <text>
                            <reference value="#lab17"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="mg/dL" value="106.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab18"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="60"/>
                                <high unit="mg/dL" value="110"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab19" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2324-2" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="GAMMGT"/>
                          <text>
                            <reference value="#lab19"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="[iU]/L" value="39.0" xsi:type="PQ"/>
                          <interpretationCode code="H" codeSystem="2.16.840.1.113883.5.83" displayName="oberhalb des Referenzbereiches"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab20"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="[iU]/L" value="0"/>
                                <high unit="[iU]/L" value="38"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab21" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2085-9" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="HDL"/>
                          <text>
                            <reference value="#lab21"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="mg/dL" value="53.0" xsi:type="PQ"/>
                          <interpretationCode code="L" codeSystem="2.16.840.1.113883.5.83" displayName="unterhalb des Referenzbereiches"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab22"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="55"/>
                                <high unit="mg/dL" value="999999"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab23" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="9830-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="QUOT"/>
                          <text>
                            <reference value="#lab23"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="{Ratio}" value="4.8" xsi:type="PQ"/>
                          <interpretationCode code="HH" codeSystem="2.16.840.1.113883.5.83" displayName="oberhalb des Alarmbereiches"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab24"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="{Ratio}" value="0"/>
                                <high unit="{Ratio}" value="3.3"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab25" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2571-8" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TRI"/>
                          <text>
                            <reference value="#lab25"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="mg/dL" value="146.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab26"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="25"/>
                                <high unit="mg/dL" value="180"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="1400" codeSystem="1.2.40.0.34.5.11" displayName="Urindiagnostik"/>
              <title>Urindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Urinstreifen</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab27" styleCode="xELGA_red">
                      <td>Leukozyten /Urin Teststreifen</td>
                      <td>70.0</td>
                      <td>/uL</td>
                      <td ID="lab28">0-10</td>
                      <td>++</td>
                    </tr>
                    <tr ID="lab29">
                      <td>Erythrozyten /Urin Teststreifen</td>
                      <td>unter 5</td>
                      <td>/uL</td>
                      <td ID="lab30">0-5</td>
                      <td></td>
                    </tr>
                    <tr ID="lab31">
                      <td>Nitrit /Urin qual. Teststreifen</td>
                      <td>negativ</td>
                      <td>{neg,pos}</td>
                      <td ID="lab32"></td>
                      <td></td>
                    </tr>
                    <tr ID="lab33">
                      <td>Urobilinogen /Urin Teststreifen</td>
                      <td>0.2</td>
                      <td>mg/dL</td>
                      <td ID="lab34">0-1</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <paragraph styleCode="xELGA_h3">Urinchemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab35">
                      <td>Glucose /Urin</td>
                      <td>negativ</td>
                      <td>mg/dL</td>
                      <td ID="lab36">0-.015</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="1400" codeSystem="1.2.40.0.34.5.11" displayName="Urindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="13730" codeSystem="1.2.40.0.34.5.11" displayName="Urinstreifen"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab27" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20408-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="LEUCOH"/>
                          <text>
                            <reference value="#lab27"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="/uL" value="70.0" xsi:type="PQ"/>
                          <interpretationCode code="HH" codeSystem="2.16.840.1.113883.5.83" displayName="oberhalb des Alarmbereiches"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab28"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="/uL" value="0"/>
                                <high unit="/uL" value="10"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab29" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20409-9" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="ERYH"/>
                          <text>
                            <reference value="#lab29"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="/uL" value="unter 5" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab30"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="/uL" value="0"/>
                                <high unit="/uL" value="5"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab31" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="5802-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="NITH"/>
                          <text>
                            <reference value="#lab31"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="{neg,pos}" value="negativ" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab32"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab33" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20405-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="UROBIH"/>
                          <text>
                            <reference value="#lab33"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="mg/dL" value="0.2" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab34"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="0"/>
                                <high unit="mg/dL" value="1"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="13750" codeSystem="1.2.40.0.34.5.11" displayName="Urinchemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab35" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2350-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="GLTH"/>
                          <text>
                            <reference value="#lab35"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="mg/dL" value="negativ" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab36"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="0"/>
                                <high unit="mg/dL" value=".015"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="2500" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
              <title>Sonstige</title>
              <text>
                <paragraph styleCode="xELGA_h3">Sonstige</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab37">
                      <td>SEDISO</td>
                      <td>mäßig Bakterien und amorphe Urate,
spärlich Leucocyten, vereinzelt
Plattenepithe</td>
                      <td></td>
                      <td ID="lab38"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="2500" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="17890" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab37" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="" codeSystem="1.2.40.0.34.99.9999.4.4" codeSystemName="doctory_lab" displayName="SEDISO"/>
                          <text>
                            <reference value="#lab37"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120321000000+0100"/>
                          <value unit="" value="mäßig Bakterien und amorphe Urate,
spärlich Leucocyten, vereinzelt
Plattenepithe" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab38"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 18.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="300" codeSystem="1.2.40.0.34.5.11" displayName="Hämatologie"/>
              <title>Hämatologie</title>
              <text>
                <paragraph styleCode="xELGA_h3">Blutbild</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab39">
                      <td>MCHC (mittlere zelluläre Hämoglobinkonzentration)</td>
                      <td>33.8</td>
                      <td>g/dL</td>
                      <td ID="lab40">29-36</td>
                      <td></td>
                    </tr>
                    <tr ID="lab41">
                      <td>Erythrozyten</td>
                      <td>4.42</td>
                      <td>10*12/L</td>
                      <td ID="lab42">3.8-5.2</td>
                      <td></td>
                    </tr>
                    <tr ID="lab43">
                      <td>Hämoglobin</td>
                      <td>13.8</td>
                      <td>g/dL</td>
                      <td ID="lab44">12-16</td>
                      <td></td>
                    </tr>
                    <tr ID="lab45">
                      <td>Hämatokrit</td>
                      <td>40.7</td>
                      <td>%</td>
                      <td ID="lab46">35-47</td>
                      <td></td>
                    </tr>
                    <tr ID="lab47">
                      <td>MCV (mittleres Zellvolumen)</td>
                      <td>92.0</td>
                      <td>fL</td>
                      <td ID="lab48">80-101</td>
                      <td></td>
                    </tr>
                    <tr ID="lab49">
                      <td>MCH (mittleres zelluläres Hämoglobin)</td>
                      <td>31.1</td>
                      <td>pg</td>
                      <td ID="lab50">27-35</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="300" codeSystem="1.2.40.0.34.5.11" displayName="Hämatologie"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="03010" codeSystem="1.2.40.0.34.5.11" displayName="Blutbild"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab39" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="28540-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="MCHC"/>
                          <text>
                            <reference value="#lab39"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="g/dL" value="33.8" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab40"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="g/dL" value="29"/>
                                <high unit="g/dL" value="36"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab41" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="26453-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="RBC"/>
                          <text>
                            <reference value="#lab41"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="10*12/L" value="4.42" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab42"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="10*12/L" value="3.8"/>
                                <high unit="10*12/L" value="5.2"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab43" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="718-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="HGB"/>
                          <text>
                            <reference value="#lab43"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="g/dL" value="13.8" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab44"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="g/dL" value="12"/>
                                <high unit="g/dL" value="16"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab45" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20570-8" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="HCT"/>
                          <text>
                            <reference value="#lab45"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="%" value="40.7" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab46"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="%" value="35"/>
                                <high unit="%" value="47"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab47" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="30428-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="MCV"/>
                          <text>
                            <reference value="#lab47"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="fL" value="92.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab48"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="fL" value="80"/>
                                <high unit="fL" value="101"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab49" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="28539-5" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="MCH"/>
                          <text>
                            <reference value="#lab49"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="pg" value="31.1" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab50"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="pg" value="27"/>
                                <high unit="pg" value="35"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="400" codeSystem="1.2.40.0.34.5.11" displayName="Gerinnung/Hämostaseologie"/>
              <title>Gerinnung/Hämostaseologie</title>
              <text>
                <paragraph styleCode="xELGA_h3">Hämostaseologie Globaltests</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab51">
                      <td>PTZ (Prothrombinzeit)</td>
                      <td>negativ</td>
                      <td>%</td>
                      <td ID="lab52">0-15</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="400" codeSystem="1.2.40.0.34.5.11" displayName="Gerinnung/Hämostaseologie"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="04140" codeSystem="1.2.40.0.34.5.11" displayName="Hämostaseologie Globaltests"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab51" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="5894-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="PROTH"/>
                          <text>
                            <reference value="#lab51"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="%" value="negativ" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab52"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="%" value="0"/>
                                <high unit="%" value="15"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab53">
                      <td>Glucose</td>
                      <td>106.0</td>
                      <td>mg/dL</td>
                      <td ID="lab54">60-110</td>
                      <td></td>
                    </tr>
                    <tr ID="lab55">
                      <td>Gamma-GT</td>
                      <td>39.0</td>
                      <td>[iU]/L</td>
                      <td ID="lab56">0-38</td>
                      <td></td>
                    </tr>
                    <tr ID="lab57">
                      <td>Cholesterin</td>
                      <td>257.0</td>
                      <td>mg/dL</td>
                      <td ID="lab58">100-200</td>
                      <td></td>
                    </tr>
                    <tr ID="lab59">
                      <td>HDL-Cholesterin</td>
                      <td>53.0</td>
                      <td>mg/dL</td>
                      <td ID="lab60">55-999999</td>
                      <td></td>
                    </tr>
                    <tr ID="lab61">
                      <td>Cholesterin/HDL-Cholesterin-Ratio</td>
                      <td>4.8</td>
                      <td>{Ratio}</td>
                      <td ID="lab62">0-3.3</td>
                      <td></td>
                    </tr>
                    <tr ID="lab63">
                      <td>Triglyceride</td>
                      <td>146.0</td>
                      <td>mg/dL</td>
                      <td ID="lab64">25-180</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab53" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2345-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="NBZP"/>
                          <text>
                            <reference value="#lab53"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="mg/dL" value="106.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab54"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="60"/>
                                <high unit="mg/dL" value="110"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab55" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2324-2" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="GAMMGT"/>
                          <text>
                            <reference value="#lab55"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="[iU]/L" value="39.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab56"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="[iU]/L" value="0"/>
                                <high unit="[iU]/L" value="38"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab57" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2093-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="CHOL"/>
                          <text>
                            <reference value="#lab57"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="mg/dL" value="257.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab58"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="100"/>
                                <high unit="mg/dL" value="200"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab59" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2085-9" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="HDL"/>
                          <text>
                            <reference value="#lab59"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="mg/dL" value="53.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab60"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="55"/>
                                <high unit="mg/dL" value="999999"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab61" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="9830-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="QUOT"/>
                          <text>
                            <reference value="#lab61"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="{Ratio}" value="4.8" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab62"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="{Ratio}" value="0"/>
                                <high unit="{Ratio}" value="3.3"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab63" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2571-8" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="TRI"/>
                          <text>
                            <reference value="#lab63"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="mg/dL" value="146.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab64"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="25"/>
                                <high unit="mg/dL" value="180"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="1400" codeSystem="1.2.40.0.34.5.11" displayName="Urindiagnostik"/>
              <title>Urindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Urinstreifen</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab65">
                      <td>Leukozyten /Urin Teststreifen</td>
                      <td>70.0</td>
                      <td>/uL</td>
                      <td ID="lab66">0-10</td>
                      <td></td>
                    </tr>
                    <tr ID="lab67">
                      <td>Erythrozyten /Urin Teststreifen</td>
                      <td>unter 5</td>
                      <td>/uL</td>
                      <td ID="lab68">0-5</td>
                      <td></td>
                    </tr>
                    <tr ID="lab69">
                      <td>Nitrit /Urin qual. Teststreifen</td>
                      <td>negativ</td>
                      <td>{neg,pos}</td>
                      <td ID="lab70"></td>
                      <td></td>
                    </tr>
                    <tr ID="lab71">
                      <td>Urobilinogen /Urin Teststreifen</td>
                      <td>0.2</td>
                      <td>mg/dL</td>
                      <td ID="lab72">0-1</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <paragraph styleCode="xELGA_h3">Urinchemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab73">
                      <td>Glucose /Urin</td>
                      <td>negativ</td>
                      <td>mg/dL</td>
                      <td ID="lab74">0-.015</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="1400" codeSystem="1.2.40.0.34.5.11" displayName="Urindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="13730" codeSystem="1.2.40.0.34.5.11" displayName="Urinstreifen"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab65" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20408-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="LEUCOH"/>
                          <text>
                            <reference value="#lab65"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="/uL" value="70.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab66"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="/uL" value="0"/>
                                <high unit="/uL" value="10"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab67" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20409-9" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="ERYH"/>
                          <text>
                            <reference value="#lab67"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="/uL" value="unter 5" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab68"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="/uL" value="0"/>
                                <high unit="/uL" value="5"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab69" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="5802-4" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="NITH"/>
                          <text>
                            <reference value="#lab69"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="{neg,pos}" value="negativ" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab70"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab71" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20405-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="UROBIH"/>
                          <text>
                            <reference value="#lab71"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="mg/dL" value="0.2" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab72"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="0"/>
                                <high unit="mg/dL" value="1"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="13750" codeSystem="1.2.40.0.34.5.11" displayName="Urinchemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab73" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2350-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="GLTH"/>
                          <text>
                            <reference value="#lab73"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="mg/dL" value="negativ" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab74"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="0"/>
                                <high unit="mg/dL" value=".015"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="2500" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
              <title>Sonstige</title>
              <text>
                <paragraph styleCode="xELGA_h3">Sonstige</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab75">
                      <td>SEDISO</td>
                      <td>mäßig Bakterien und amorphe Urate,
spärlich Leucocyten, vereinzelt
Plattenepithe</td>
                      <td></td>
                      <td ID="lab76"></td>
                      <td></td>
                    </tr>
                    <tr ID="lab77">
                      <td>Kommentar</td>
                      <td>Text</td>
                      <td></td>
                      <td ID="lab78"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="2500" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="17890" codeSystem="1.2.40.0.34.5.11" displayName="Sonstige"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab75" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="" codeSystem="1.2.40.0.34.99.9999.4.4" codeSystemName="doctory_lab" displayName="SEDISO"/>
                          <text>
                            <reference value="#lab75"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="" value="mäßig Bakterien und amorphe Urate,
spärlich Leucocyten, vereinzelt
Plattenepithe" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab76"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab77" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="" codeSystem="1.2.40.0.34.99.9999.4.4" codeSystemName="doctory_lab" displayName="Kommentar"/>
                          <text>
                            <reference value="#lab77"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120618000000+0100"/>
                          <value unit="" value="Text" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab78"/>
                              </text>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
        <section classCode="DOCSECT">
          <templateId root="1.2.40.0.34.6.0.11.2.104"/>
          <code code="LabSpecContainer" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Laboratory Speciality Container"/>
          <title>Laborparameter 21.06.2012</title>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="300" codeSystem="1.2.40.0.34.5.11" displayName="Hämatologie"/>
              <title>Hämatologie</title>
              <text>
                <paragraph styleCode="xELGA_h3">Blutbild</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab79">
                      <td>Hämatokrit</td>
                      <td>39.5</td>
                      <td>%</td>
                      <td ID="lab80">35-47</td>
                      <td></td>
                    </tr>
                    <tr ID="lab81">
                      <td>Erythrozyten</td>
                      <td>4.1</td>
                      <td>10*12/L</td>
                      <td ID="lab82">3.8-5.2</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="300" codeSystem="1.2.40.0.34.5.11" displayName="Hämatologie"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="03010" codeSystem="1.2.40.0.34.5.11" displayName="Blutbild"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab79" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20570-8" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="HCT"/>
                          <text>
                            <reference value="#lab79"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120621000000+0100"/>
                          <value unit="%" value="39.5" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab80"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="%" value="35"/>
                                <high unit="%" value="47"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab81" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="26453-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="RBC"/>
                          <text>
                            <reference value="#lab81"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120621000000+0100"/>
                          <value unit="10*12/L" value="4.1" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab82"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="10*12/L" value="3.8"/>
                                <high unit="10*12/L" value="5.2"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
              <title>Klinische Chemie/Proteindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Klinische Chemie</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab83">
                      <td>Cholesterin</td>
                      <td>261.0</td>
                      <td>mg/dL</td>
                      <td ID="lab84">100-200</td>
                      <td></td>
                    </tr>
                    <tr ID="lab85">
                      <td>Gamma-GT</td>
                      <td>40.0</td>
                      <td>[iU]/L</td>
                      <td ID="lab86">0-38</td>
                      <td></td>
                    </tr>
                    <tr ID="lab87">
                      <td>Glucose</td>
                      <td>111.0</td>
                      <td>mg/dL</td>
                      <td ID="lab88">60-110</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="500" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie/Proteindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="05180" codeSystem="1.2.40.0.34.5.11" displayName="Klinische Chemie"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab83" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2093-3" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="CHOL"/>
                          <text>
                            <reference value="#lab83"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120621000000+0100"/>
                          <value unit="mg/dL" value="261.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab84"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="100"/>
                                <high unit="mg/dL" value="200"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab85" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2324-2" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="GAMMGT"/>
                          <text>
                            <reference value="#lab85"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120621000000+0100"/>
                          <value unit="[iU]/L" value="40.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab86"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="[iU]/L" value="0"/>
                                <high unit="[iU]/L" value="38"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab87" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="2345-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="NBZP"/>
                          <text>
                            <reference value="#lab87"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120621000000+0100"/>
                          <value unit="mg/dL" value="111.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab88"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="60"/>
                                <high unit="mg/dL" value="110"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
          <component typeCode="COMP">
            <section classCode="DOCSECT">
              <templateId root="1.3.6.1.4.1.19376.1.3.3.2.1"/>
              <id extension="P-body" root="2.16.840.1.113883.3.933.1.1"/>
              <code code="1400" codeSystem="1.2.40.0.34.5.11" displayName="Urindiagnostik"/>
              <title>Urindiagnostik</title>
              <text>
                <paragraph styleCode="xELGA_h3">Urinstreifen</paragraph>
                <table>
                  <thead>
                    <tr>
                      <th>Analyse</th>
                      <th>Ergebnis</th>
                      <th>Einheit</th>
                      <th>Referenz</th>
                      <th>Interpretation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ID="lab89">
                      <td>Urobilinogen /Urin Teststreifen</td>
                      <td>0.2</td>
                      <td>mg/dL</td>
                      <td ID="lab90">0-1</td>
                      <td></td>
                    </tr>
                    <tr ID="lab91">
                      <td>Leukozyten /Urin Teststreifen</td>
                      <td>68.0</td>
                      <td>/uL</td>
                      <td ID="lab92">0-10</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </text>
              <entry typeCode="DRIV">
                <templateId extension="Lab.Report.Data.Processing.Entry" root="1.3.6.1.4.1.19376.1.3.1"/>
                <act classCode="ACT" moodCode="EVN">
                  <code code="1400" codeSystem="1.2.40.0.34.5.11" displayName="Urindiagnostik"/>
                  <statusCode code="completed"/>
                  <entryRelationship typeCode="COMP">
                    <organizer classCode="BATTERY">
                      <templateId root="1.3.6.1.4.1.19376.1.3.1.4"/>
                      <code code="13730" codeSystem="1.2.40.0.34.5.11" displayName="Urinstreifen"/>
                      <statusCode code="completed"/>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab89" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20405-7" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="UROBIH"/>
                          <text>
                            <reference value="#lab89"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120621000000+0100"/>
                          <value unit="mg/dL" value="0.2" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab90"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="mg/dL" value="0"/>
                                <high unit="mg/dL" value="1"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                      <component typeCode="COMP">
                        <observation classCode="OBS" moodCode="EVN">
                          <templateId root="1.3.6.1.4.1.19376.1.3.1.6"/>
                          <id extension="lab91" root="1.2.40.0.34.99.4613.122082.1.3.5"/>
                          <code code="20408-1" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="LEUCOH"/>
                          <text>
                            <reference value="#lab91"/>
                          </text>
                          <statusCode code="completed"/>
                          <effectiveTime value="20120621000000+0100"/>
                          <value unit="/uL" value="68.0" xsi:type="PQ"/>
                          <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                          <referenceRange typeCode="REFV">
                            <observationRange classCode="OBS" moodCode="EVN.CRT">
                              <text>
                                <reference value="#lab92"/>
                              </text>
                              <value xsi:type="IVL_PQ">
                                <low unit="/uL" value="0"/>
                                <high unit="/uL" value="10"/>
                              </value>
                              <interpretationCode code="N" codeSystem="2.16.840.1.113883.5.83" displayName="Normalbereich"/>
                            </observationRange>
                          </referenceRange>
                        </observation>
                      </component>
                    </organizer>
                  </entryRelationship>
                </act>
              </entry>
            </section>
          </component>
        </section>
      </component>
      <component>
        <section>
          <templateId root="1.2.40.0.34.6.0.11.2.34"/>
          <code code="KARTEI_EINTRAGUNGEN" codeSystem="1.2.40.0.34.5.194" codeSystemName="EXNDS_Sections" displayName="Karteineintragungen"/>
          <title>Karteineintragungen</title>
          <text>
            <table>
              <thead>
                <tr>
                  <th>Zeile</th>
                  <th>Datum</th>
                  <th>Kürzel</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>12.06.2012 18:16</td>
                  <td>BF</td>
                  <td ID="kartei1">für die entstandenen Umstände
bedanken wir uns herzlichst</td>
                </tr>
              </tbody>
            </table>
          </text>
          <entry typeCode="DRIV">
            <organizer classCode="BATTERY" moodCode="EVN">
              <templateId root="1.2.40.0.34.6.0.11.3.137"/>
              <id assigningAuthorityName="Dr. Test Osteron" extension="kartei" root="1.2.40.0.34.99.9999.5.99"/>
              <code code="Karteieintragungen" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintragungen"/>
              <statusCode code="completed"/>
              <effectiveTime>
                <low value="20220228214615+0100"/>
              </effectiveTime>
              <component contextConductionInd="true" typeCode="COMP">
                <observation classCode="OBS" moodCode="EVN" negationInd="false">
                  <templateId root="1.2.40.0.34.6.0.11.3.136"/>
                  <id assigningAuthorityName="Dr. Test Osteron" extension="karteizeile1" root="1.2.40.0.34.99.9999.5.99"/>
                  <code code="Karteineintrag" codeSystem="1.2.40.0.34.5.195" codeSystemName="EXNDS_Concepts" displayName="Karteineintrag"/>
                  <text>
                    <reference value="#kartei1"/>
                  </text>
                  <effectiveTime>
                    <low value="20120612181600+0100"/>
                  </effectiveTime>
                  <value value="1" xsi:type="INT"/>
                </observation>
              </component>
            </organizer>
          </entry>
        </section>
      </component>
      <component/>
    </structuredBody>
  </component>
</ClinicalDocument>
`;
